import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL, WEBSITE_API_URL_NEW } from "../../config";

const initialState = {
    order_data: [],
    track_order: [],
    single_order: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const getAllOrders = createAsyncThunk("shop/getAllOrders", async (formData) => {
    try {
        const response = await axios.get(
            `${WEBSITE_API_URL_NEW}/order?type=all`,
            {
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getOngoingOrders = createAsyncThunk("shop/getOngoingOrders", async (formData) => {
    try {
        const response = await axios.get(
            `${WEBSITE_API_URL_NEW}/ongoing/order`,
            {
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const codOrders = createAsyncThunk("store/codOrders", async (formData) => {
    try {
        const response = await axios.POST(WEBSITE_API_URL + "/V1/user/place-order", formData);
        return response.data;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});
export const orderHistory = createAsyncThunk("store/orderHistory", async (formData) => {
    try {
        const response = await axios.POST(WEBSITE_API_URL + "/V1/user/show-orders", formData);
        return response.data;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});



export const getSingleOrder = createAsyncThunk("store/getSingleOrder", async (formData) => {
    const { id } = formData;

    try {
        const response = await axios.get(
            `${WEBSITE_API_URL_NEW}/order/${id}`,
            {
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

// export const sendOrderReview = createAsyncThunk("store/sendOrderReview", async (formData) => {
//     try {
//         const response = await axios.post(WEBSITE_API_URL + "/send-order-review", formData);
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         throw error;
//     }
// });

// export const getTrackOrder = createAsyncThunk("store/getTrackOrder", async (formData) => {
//     try {
//         const response = await axios.post(WEBSITE_API_URL + "/get-track-order", formData);
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         throw error;
//     }
// });

// export const cancelNewOrder = createAsyncThunk("store/cancelNewOrder", async (formData) => {
//     try {
//         const response = await axios.post(WEBSITE_API_URL + "/cancel-new-order", formData);
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         throw error;
//     }
// });

// export const getReOrderItems = createAsyncThunk("store/getReOrderItems", async (formData) => {
//     try {
//         const response = await axios.post(WEBSITE_API_URL + "/get-reorder-items", formData);
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         throw error;
//     }
// });

const orderSlice = createSlice({
    name: "order",
    initialState: initialState,
    reducers: {
        orderCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        [getAllOrders.pending]: (state,) => {
            state.loading = true;
        },
        [getAllOrders.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.order_data = payload;
            state.isSuccess = true;
        },
        [getAllOrders.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [getOngoingOrders.pending]: (state,) => {
            state.loading = true;
        },
        [getOngoingOrders.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.ongoing_order = payload;
            state.isSuccess = true;
        },
        [getOngoingOrders.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [codOrders.pending]: (state,) => {
            state.loading = true;
        },
        [codOrders.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.order_data = payload;
            state.isSuccess = true;
        },
        [codOrders.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [orderHistory.pending]: (state,) => {
            state.loading = true;
        },
        [orderHistory.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.order_data = payload;
            state.isSuccess = true;
        },
        [orderHistory.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        // [cancelNewOrder.pending]: (state,) => {
        //     state.loading = true;
        // },
        // [cancelNewOrder.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.order_data = payload;
        //     state.isSuccess = true;
        // },
        // [cancelNewOrder.rejected]: (state, { payload }) => {
        //     state.message = payload;
        //     state.loading = false;
        //     state.isSuccess = false;
        // },

        [getSingleOrder.pending]: (state,) => {
            state.loading = true;
        },
        [getSingleOrder.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.single_order = payload;
            state.isSuccess = true;
        },
        [getSingleOrder.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        // [getTrackOrder.pending]: (state,) => {
        //     state.loading = true;
        // },
        // [getTrackOrder.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.track_order = payload;
        //     state.isSuccess = true;
        // },
        // [getTrackOrder.rejected]: (state, { payload }) => {
        //     state.message = payload;
        //     state.loading = false;
        //     state.isSuccess = false;
        // },
    },
});


export const { orderCurrentTenant } = orderSlice.actions;

export default orderSlice.reducer;
