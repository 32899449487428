// import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux';

// function BillDetails({ tip, cartData, discount }) {
//     const cartProducts = useSelector((state) => state.cart);
//     const [delivery_charge, setDeliveryCharge] = useState(0);

//     const totalPrice = () => {
//         let tax = 0;
//         let surge_charge = 0;

//         let packing_charge = 0;

//         if (discount > 0) {
//             return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
//         } else {
//             return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
//         }
//     }

//     useEffect(() => {
//         setDeliveryCharge(cartData?.orders?.delivery_charge);
//     }, [cartData])

//     return (
//         <div>
//             <section class="bill-details section-lg-b-space" style={{ paddingBottom: '8rem' }}>
//                 <div class="custom-container">
//                     <h3 class="fw-semibold dark-text">Bill Details</h3>
//                     <div class="total-detail mt-3">
//                         <div class="sub-total">
//                             <h5>Sub Total</h5>
//                             <h5 class="fw-semibold">₹{parseFloat(cartProducts.totalAmount).toFixed(2)}</h5>
//                         </div>
//                         {parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge) > 0 &&
//                             <div class="sub-total mt-3">
//                                 <h5>Packing Charge</h5>
//                                 <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge).toFixed(2)}</h5>
//                             </div>
//                         }
//                         <div class="sub-total mt-3">
//                             <h5>Delivery Charge</h5>
//                             <h5 class="fw-semibold">
//                                 {/* {cartProducts?.shop_distance?.shop_id?.free_delivery && cartProducts?.shop_distance?.shop_id?.free_delivery_subtotal <= cartProducts.totalAmount ? (
//                                     <h5 style={{ color: '#fbdb2c' }} >Free</h5>
//                                 ) : (
//                                     <> */}
//                                 {delivery_charge > 0 ?
//                                     <h5>₹{parseFloat(delivery_charge).toFixed(2)}</h5>
//                                     :
//                                     <h5 style={{ color: '#fbdb2c' }} >Free</h5>
//                                 }
//                                 {/* </>
//                                 )} */}
//                             </h5>
//                         </div>
//                         {/* <h6 class="delivery-info light-text mt-2">Save ₹5 on Delivery fee by ordering above ₹30</h6> */}
//                         {discount > 0 &&
//                             <div class="sub-total mt-3">
//                                 <h5>Discount</h5>
//                                 <h5 class="fw-semibold" style={{ color: '#fbdb2c' }}>{"-₹" + discount}</h5>
//                             </div>
//                         }
//                         {localStorage.getItem('tip') > 0 &&
//                             <div class="sub-total mt-3">
//                                 <h5>Tip</h5>
//                                 <h5 class="fw-semibold">₹{parseFloat(localStorage.getItem('tip')).toFixed(2)}</h5>
//                             </div>
//                         }
//                         {parseFloat(cartProducts?.shop_tax?.surge_charge) > 0 &&
//                             <div class="sub-total mt-3">
//                                 <h5>Surge Charge</h5>
//                                 <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_tax?.surge_charge).toFixed(2)}</h5>
//                             </div>
//                         }
//                         {parseFloat(cartProducts?.shop_tax?.tax) > 0 &&
//                             <div class="sub-total mt-3">
//                                 <h5>Tax ({parseFloat(cartProducts?.shop_tax?.tax)}%)</h5>
//                                 <h5 class="fw-semibold" >₹{((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100).toFixed(2)}</h5>
//                             </div>
//                         }

//                         <div class="grand-total mt-3">
//                             <h5 class="fw-semibold">Grand Total</h5>
//                             <h5 class="fw-semibold amount" style={{ color: '#fcd605',fontWeight:'bold' }}>{totalPrice()}</h5>
//                         </div>
//                         <img class="dots-design" src="assets/images/svg/dots-design.svg" alt="dots" />
//                     </div>
//                 </div>
//             </section>
//         </div>
//     )
// }

// export default BillDetails








import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function BillDetails({ tip, cartData, discount }) {
    const cartProducts = useSelector((state) => state.cart);
    console.log("my discountt ", discount);
    
    const [delivery_charge, setDeliveryCharge] = useState(0);

    // const totalPrice = () => {
    //     let tax = 0;
    //     let surge_charge = 0;

    //     let packing_charge = 0;

    //     if (discount > 0) {
    //         return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    //     } else {
    //         return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    //     }
    // }




    const totalPrice = () => {
        
        let tax = 0;
        let surge_charge = cartData?.orders?.surge_charge; 
  

        let service_charge = cartData?.orders?.extra_charges?.service_charge?.serviceChargeAmount || 0;
        

        let packing_charge = cartData?.orders?.extra_charges?.packing_charge?.packingChargeAmount || 0;

        if (discount > 0) {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(service_charge)+ parseFloat(packing_charge)).toFixed(2)
        } else {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge)+ parseFloat(service_charge) + parseFloat(packing_charge)).toFixed(2)
        }
    }

    useEffect(() => {
        setDeliveryCharge(cartData?.orders?.delivery_charge);
    }, [cartData])

    return (
        <div>
            <section class="bill-details section-lg-b-space" style={{ paddingBottom: '8rem' }}>
                <div class="custom-container">
                    <h3 class="fw-semibold dark-text">Bill Details</h3>
                    <div class="total-detail mt-3">
                        <div class="sub-total">
                            <h5>Sub Total</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartProducts.totalAmount).toFixed(2)}</h5>
                        </div>

                        <div class="sub-total mt-3">
                            <h5>Surge Charge</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartData?.orders?.surge_charge || 0).toFixed(2)}</h5>
                        </div>


                        <div class="sub-total mt-3">
                            <h5>Service Charge</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartData?.orders?.extra_charges?.service_charge?.serviceChargeAmount || 0).toFixed(2)}</h5>
                        </div>

                        <div class="sub-total mt-3">
                            <h5>Service Tax</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartData?.orders?.tax || 0).toFixed(2)}</h5>
                        </div>

                        <div class="sub-total mt-3">
                            <h5>Packaging Charge</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartData?.orders?.extra_charges?.packing_charge?.packingChargeAmount || 0).toFixed(2)}</h5>
                        </div>

                        {parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Packing Charge</h5>
                                <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge).toFixed(2)}</h5>
                            </div>
                        }
                        <div class="sub-total mt-3">
                            <h5>Delivery Charge</h5>
                            <h5 class="fw-semibold">
                                
                                {delivery_charge > 0 ?
                                    <h5>₹{parseFloat(delivery_charge).toFixed(2)}</h5>
                                    :
                                    <h5 style={{ color: '#fbdb2c' }} >Free</h5>
                                }
                               
                            </h5>
                        </div>
                        {/* <h6 class="delivery-info light-text mt-2">Save ₹5 on Delivery fee by ordering above ₹30</h6> */}
                        {discount > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Discount</h5>
                                <h5 class="fw-semibold" style={{ color: '#fbdb2c' }}>{"-₹" + discount}</h5>
                            </div>
                        }
                       
                        {parseFloat(cartProducts?.shop_tax?.surge_charge) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Surge Charge</h5>
                                <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_tax?.surge_charge).toFixed(2)}</h5>
                            </div>
                        }
                        {localStorage.getItem('tip') > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Tip</h5>
                                <h5 class="fw-semibold">₹{parseFloat(localStorage.getItem('tip')).toFixed(2)}</h5>
                            </div>
                        }
                        {parseFloat(cartProducts?.shop_tax?.tax) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Tax ({parseFloat(cartProducts?.shop_tax?.tax)}%)</h5>
                                <h5 class="fw-semibold" >₹{((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100).toFixed(2)}</h5>
                            </div>
                        }

                        <div class="grand-total mt-3">
                            <h5 class="fw-semibold">Grand Total</h5>
                            <h5 class="fw-semibold amount" style={{ color: '#fcd605',fontWeight:'bold' }}>{totalPrice()}</h5>
                        </div>
                        <img class="dots-design" src="assets/images/svg/dots-design.svg" alt="dots" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BillDetails