import React, { useEffect, useState } from 'react'
import { getOngoingOrders, getSingleOrder } from '../../../Redux/Store/orderReducer'
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../../assets/others/track-order-loading.json'
import cube from '../../../assets/svg/cube.svg'
import cube2 from '../../../assets/svg/3d-cube.svg'
import bike from '../../../assets/svg/Scooter.svg'
import done from '../../../assets/svg/done.svg'
import dots from '../../../assets/svg/dots-design.svg'
import delivery from '../../../assets/images/delivery.png'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Drawer } from "@material-ui/core";
import { RiCloseCircleLine } from 'react-icons/ri';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

function Ordertracking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();

  const [orders, setOrders] = useState(null);
  const [order, setOrder] = useState(null);
  const [ordered, setOrdered] = useState(null);
  const [received, setReceived] = useState(null);
  const [picked, setPicked] = useState(null);
  const [delivered, setDelivered] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getOngoingOrders()).then((response) => {
        if (response && response.payload && response.payload?.message === 'success') {
          setOrders(response.payload?.result);

          const odr = response.payload?.result?.find(o => o.id == id);
          if (odr) {
            setOrdered(odr?.ordertiming.find(time => time.status === "ORDERED")?.created_at);
            setReceived(odr?.ordertiming.find(time => time.status === "RECEIVED")?.created_at);
            setPicked(odr?.ordertiming.find(time => time.status === "PICKEDUP")?.created_at);
            setDelivered(odr?.ordertiming.find(time => time.status === "COMPLETED")?.created_at);
          }
        } else {
          console.log('error');
        }
      });

      dispatch(getSingleOrder({ id: id })).then((response) => {
        if (response && response.payload && response.payload?.message === 'success') {
          setOrder(response.payload?.result);
          // setTimeLeft(response.payload?.result?.shop?.zones?.order_cancellation_time);
        }
        setLoading(false);
      });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch, id]);

  const handleDelete = async (orderId) => {
    try {
      const response = await axios.delete(
        `https://ciboee.com/api/user/order/${orderId}`, 
        {
          headers: {
            Authorization: localStorage.getItem('device_token'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Response:', response.data);
      navigate('/order-history');
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  function formatTime(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  return (
    <div>
      {loading ? (
        <div style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <div>
          <header class="section-t-space">
            <div class="custom-container">
              <div class="header-panel">
                <Link to={'/order-history'}>
                  <i class="ri-arrow-left-s-line"></i>
                </Link>
                <h2>Order Tracking</h2>
              </div>
            </div>
          </header>
          <section>
            <div class="custom-container">
              <h5 class="dark-text"><span class="light-text">Order ID : </span>{order?.invoice_id}</h5>
              <div class="order-tracking mt-3">
                <ul class="tracking-place">
                  <li class="active">
                    <h6 style={{ width: '4rem', marginLeft: '10px' }}>
                      {ordered ? moment(ordered).format('hh:mm A') : ' -- : -- -- '}
                    </h6>
                    <span></span>
                    <img class="img-fluid ms-3 icon step-1" src={cube} alt="cube" />
                    <h6 class="color-1 ms-2">Order Placed</h6>
                  </li>
                  <li className={received ? 'active' : ''}>
                    <h6 style={{ width: '4rem', marginLeft: '10px' }}>
                      {received ? moment(received).format('hh:mm A') : ' -- : -- -- '}
                    </h6>
                    <span></span>
                    <img class="img-fluid ms-3 icon step-2" src={cube2} alt="3d-cube" />

                    <h6 class="color-2 ms-2">Preparing food</h6>
                  </li>
                  <li className={picked ? 'active' : ''}>
                    <h6 style={{ width: '4rem', marginLeft: '10px' }}>
                      {picked ? moment(picked).format('hh:mm A') : ' -- : -- -- '}
                    </h6>
                    <span></span>
                    <img class="img-fluid ms-3 icon step-5" src={bike} alt="bike" />

                    <h6 class="color-5 ms-2">Food on the way</h6>
                  </li>
                  <li className={delivered ? 'active p-0' : 'p-0'}>
                    <h6 style={{ width: '4rem', marginLeft: '10px' }}>
                      {delivered ? moment(delivered).format('hh:mm A') : ' -- : -- -- '}
                    </h6>
                    <span></span>
                    <img class="img-fluid ms-3 icon step-4" src={done} alt="done" />
                    <h6 class={"color-4 ms-2"}>{"Delivery to you"}</h6>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {order?.status == 'INITIATED' && timeLeft > 0 &&
            <div className='custom-container mt-4 mx-3 py-2'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '18px',
                border: '1px solid red',
                borderRadius: '10px',
                color: 'red'
              }} onClick={() => { setCancelOpen(true) }}>
              <div>
                Cancel Order
              </div>
              <div>
                ({timeLeft}s)
              </div>
            </div>
          }
          <section class="bill-details">
            <div class="custom-container">
              <div class="total-detail">
                {order?.order?.items?.map((item) => (
                  <div class="sub-total pb-3">
                    <h5>{item?.name} (x{item?.quantity})</h5>
                    <h5 class="fw-semibold">₹{item?.total_price}</h5>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section class="bill-details section-lg-b-space">
            <div class="custom-container">
              <div class="total-detail">
                <div class="sub-total pb-3">
                  <h5>Sub Total</h5>
                  <h5 class="fw-semibold ">₹{parseFloat(order?.order?.charges?.gross_without_discount).toFixed(2)}</h5>
                </div>
                
                <div class="sub-total pb-3">
                  <h5>Service Charge</h5>
                  <h5 class="fw-semibold ">₹{parseFloat(order?.order?.charges?.service_charge).toFixed(2)}</h5>
                </div>

                <div class="sub-total pb-3">
                  <h5>Surge Charge</h5>
                  <h5 class="fw-semibold ">₹{parseFloat(order?.order?.charges?.surge_charge).toFixed(2)}</h5>
                </div>

                <div class="sub-total pb-3">
                  <h5>Packing Charge</h5>
                  <h5 class="fw-semibold ">₹{parseFloat(order?.order?.charges?.packing_charge).toFixed(2)}</h5>
                </div>

                <div class="sub-total pb-3">
                  <h5>Delivery Charge  </h5>
                  <h5 class="fw-semibold">{order?.order?.charges?.delivery_charge > 0 ? '₹' + parseFloat(order?.order?.charges?.delivery_charge).toFixed(2) : <span style={{ color: 'green' }}>Free</span>}</h5>
                </div>

                {parseFloat(order?.order?.charges?.tax) > 0 &&
                  <div class="sub-total pb-3">
                    <h5>Tax</h5>
                    <h5 class="fw-semibold">₹{parseFloat(order?.order?.charges?.tax).toFixed(2)}</h5>
                  </div>
                }
                {(order?.order?.charges?.gross_without_discount - order?.order?.charges?.gross) > 0 &&
                  <div class="sub-total pb-3">
                    <h5>Discount</h5>
                    <h5 class="fw-semibold">₹{parseFloat(order?.order?.charges?.gross_without_discount - order?.order?.charges?.gross).toFixed(2)}</h5>
                  </div>
                }
                <div class="grand-total">
                  <h5 class="fw-bold">Grand Total</h5>
                  <h5 class="fw-bold">₹{order?.order?.charges?.payable} </h5>
                </div>
                <img class="dots-design" src={dots} alt="dots" />
              </div>
              <div class="delivery-time mt-4">
                <p class="delivery-line mb-0 m-0 p-0">A Moments of Delivered on Time</p>
                <img class="img-fluid delivery-bike" src={delivery} alt="delivery" />
              </div>
            </div>
          </section>
          <Drawer
            anchor="bottom"
            open={cancelOpen}
            onClose={() => setCancelOpen(false)}
            onOpen={() => setCancelOpen(true)}
            swipeAreaWidth={20}
          >
            <div className='p-3'>
              <div className='d-flex justify-content-end align-items-end'>
                <div onClick={() => { setCancelOpen(false) }}>
                  <RiCloseCircleLine size={25} />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <HiOutlineExclamationCircle size={95} color='#fbdb2c' />
                <div className='text-slate-500 fw-450 mt-1'>Are you sure want to cancel the Order?</div>
              </div>
            </div>
            <div className="px-3 py-3 pb-4 w-100 bg-white">
              <button type="submit" className="get-start-button" onClick={() => handleDelete(id)}>
                Cancel
              </button>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  )
}

export default Ordertracking