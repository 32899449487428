import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCategoryStores } from '../../Redux/Store/storeReducer';
import ProgressiveImage from 'react-progressive-image'; 
import { MdStars } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import delivery from "../../assets/svg/freeDelivery.svg";
import Lottie from 'react-lottie';
import load from "../../assets/others/track-order-loading.json";
import no_store from "../../assets/images/no_store.png";
import { IMAGE_BASE_URL } from '../../config';
import FloatCart from '../FloatCart';
import no_image from '../../assets/images/rp1.png'


function Singlecategory() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let { id, type } = useParams();

  const restaurants = useSelector((state) => state?.store?.category_stores);

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("user_id"));
    formData.append("token", localStorage.getItem("device_token"));
    formData.append("category_id", id);
    if (type === "FOOD") { 
      formData.append("type", "FOOD");
    } else if (type === "GROCERY") {
      formData.append("type", "GROCERY");
      formData.append("store_category_id", 2);

    }

    dispatch(getCategoryStores(formData)).then((response) => {
      if (response.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch((error) => {
      console.error("Fetching store failed", error);
      setLoading(false);
    });
  }, []);


  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {loading ?
        <div style={{ paddingTop: "35vh" }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>
          <header class="section-t-space">
            <div class="custom-container">
              <div class="d-flex align-items-center pb-2">
                <div onClick={() => { window.history.back() }}>
                  <i class="ri-arrow-left-s-line fs-3"></i>
                </div>
                <h2 className='flex-grow-1 text-center' style={{ fontWeight: '550' }}>{restaurants?.category?.name}</h2>
              </div>
            </div>
          </header>
          <div className="" style={{ padding: '24px 15px 0px 15px' }}>
            {restaurants?.stores?.length > 0 ?
              restaurants?.stores?.map((store, index) => (
                <div key={index}>
                  <div className="restaurants-cafe-box d-flex align-items-center position-relative mb-3 "  >
                    <Link to={`/single-store/${store.id}`} style={{ width: '40%' }}>
                      <ProgressiveImage
                        delay={20}
                        src={IMAGE_BASE_URL + store.image}
                        placeholder={no_image}
                      >
                        {(src) => (
                          <img
                            src={src}
                            style={{
                              height: "10rem",
                              width: "100%",
                              borderRadius: "22px",
                              objectFit: "cover",
                              backgroundRepeat: "round",
                            }}
                            className=""
                            alt="zeato"
                          />
                        )}
                      </ProgressiveImage>
                    </Link>
                    <Link to={`/single-store/${store.id}`} style={{ width: "60%" }}>
                      <div
                        className="flex-grow-1 d-flex flex-column justify-content-center my-1 "
                        style={{ marginLeft: "12px", }}
                      >

                        <h5
                          className=" m-0 "
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "2",
                            overflow: "hidden",
                            color: "#09051C",
                            fontWeight: "700",
                            fontSize: "20px",
                            textAlign: "left",
                          }}
                        > {store?.shop_name ?? ""} 
                        </h5>
                        <div
                          className=" d-flex flex-row align-items-center  font-semibold text-xl  mt-2"
                          style={{ fontSize: "16px", color: "black", fontWeight: "700" }}
                        >
                          <div className="d-flex flex-row align-items-center" >
                            <MdStars style={{ color: "#055438" }} size={24} />{" "}
                            {parseFloat(store?.rating).toFixed(1)}
                          </div>
                          <div className="">
                            <BsDot style={{ color: "black" }} size={14} />
                          </div>
                          <div>
                            {store?.delivery_time}min
                          </div>
                        </div>

                        <div
                          className=""
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "2",
                            overflow: "hidden",
                            color: "#545454",
                            textAlign: "left",
                          }}
                        > {store?.description ?? ""} 
                        </div>
                        <div className="d-flex flex-row align-items-center ">
                          <div
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: "2",
                              overflow: "hidden",
                              color: "#545454",
                              textAlign: "left",
                            }}
                          >{store?.landmark}
                          </div>
                          {store?.landmark ?
                            <div className="">
                              <BsDot style={{ color: "black" }} size={14} />
                            </div>
                            : null}
                          <div>
                            <div
                              className=""
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: "2",
                                overflow: "hidden",
                                color: "#545454",
                                textAlign: "left",
                              }}
                            > {store?.delivery_radius}km
                            </div>
                          </div>
                        </div>
                        {store?.free_delivery ?
                          <div className="flex flex-start">
                            <img src={delivery} style={{ height: "40px" }} alt='free_delivery' />
                          </div>
                          : null}
                      </div>
                    </Link>
                  </div>
                </div>
              )) :
              <div className="d-flex flex-column align-items-center justify-center mt-5" >
                <img src={no_store} alt='delevro' />
                <div style={{ fontWeight: '550' }}>
                  No Stores Found
                </div>
              </div>
            }
          </div>
        </div>
      }
      <FloatCart type={true} />
    </div>
  )
}

export default Singlecategory