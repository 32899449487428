import React from 'react'

function Landing() {
  return (
    <div>
        
    {/* <!-- side bar start --> */}
    <div className="offcanvas sidebar-offcanvas offcanvas-start" tabIndex="-1" id="offcanvasLeft">
        <div className="offcanvas-header">
            <img className="img-fluid profile-pic" src="assets/images/icons/profile.png" alt="profile" />
            <h3>Hello, Lina John</h3>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="sidebar-content">
                <ul className="link-section">
                    <li>
                        <div className="pages">
                            <h3>RTL</h3>
                            <div className="switch-btn">
                                <input id="dir-switch" type="checkbox" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="pages">
                            <h3>Dark</h3>
                            <div className="switch-btn">
                                <input id="dark-switch" type="checkbox" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <a href="page-listing" className="pages">
                            <h3>Pages List</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>

                    <li>
                        <a href="setting" className="pages">
                            <h3>Setting</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="pages">
                            <h3>Privacy Policy</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>

                    <li>
                        <a href="login" className="pages">
                            <h3>Logout</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    {/* <!-- side bar end --> */}

    {/* <!-- header start --> */}
    <header className="section-t-space">
        <div className="custom-container">
            <div className="header">
                <div className="head-content">
                    <button className="sidebar-btn" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasLeft">
                        <i className="ri-menu-line"></i>
                    </button>
                    <div className="header-location">
                        <a href="#location" data-bs-toggle="modal">
                            <h2>Ontario, Canada</h2>
                            <i className="ri-arrow-down-s-line d-arrow"></i>
                        </a>
                    </div>
                </div>
                <a href="notification">
                    <i className="ri-notification-2-fill"></i>
                </a>
            </div>
        </div>
    </header>
    {/* <!-- header end --> */}

    {/* <!-- search section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <form className="auth-form search-head" target="_blank">
                <div className="form-group">
                    <div className="form-input">
                        <input type="search" className="form-control search" id="inputusername"
                            placeholder="Restaurant, item & more" />
                        <i className="ri-search-line search-icon"></i>
                    </div>

                    <a href="#search-filter" className="btn filter-button mt-0" data-bs-toggle="modal">
                        <i className="ri-equalizer-line"></i>
                    </a>
                </div>
            </form>
        </div>
    </section>
    {/* <!-- search section end --> */}

    {/* <!-- categories section starts --> */}
    <section>
        <div className="custom-container">
            <div className="row">
                <div className="col-4">
                    <div className="main-categories-box">
                        <a href="food-home">
                            <div className="main-categories-img">
                                <img className="img-fluid img" src="assets/images/landing/food.png" alt="food" />
                            </div>
                            <h6 className="dark-text">Food</h6>
                        </a>
                    </div>
                </div>
                <div className="col-4">
                    <div className="main-categories-box">
                        <a href="grocery-home">
                            <div className="main-categories-img">
                                <img className="img-fluid img" src="assets/images/landing/grocery.png" alt="grocery" />
                            </div>
                            <h6 className="dark-text">Grocery</h6>
                        </a>
                    </div>
                </div>
                <div className="col-4">
                    <div className="main-categories-box">
                        <a href="pharmacy-home">
                            <div className="main-categories-img">
                                <img className="img-fluid img" src="assets/images/landing/pharmacy.png" alt="pharmacy" />
                            </div>
                            <h6 className="dark-text">Pharmacy</h6>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- categories section starts --> */}

    {/* <!-- offer banner starts --> */}
    <section>
        <div className="custom-container">
            <div className="offer-deal-box">
                <img className="img-fluid deal-img" src="assets/images/landing/discount-tag.png" alt="discount"/>
                <h5>Deal Of The Day & Other Great Offers</h5>
            </div>
        </div>
    </section>
    {/* <!-- offer banner end --> */}

    {/* <!-- banner section starts --> */}
    <section>
        <div className="custom-container">
            <div className="row g-3">
                <div className="col-12">
                    <div className="main-banner-box">
                        <img className="banner-img img-fluid w-100" src="assets/images/landing/banner7.png" alt="banner"/>
                        <div className="main-banner-details">
                            <h3>Medicine and where to find them!</h3>
                            <h5>Medicine deliver at your door:<span> 20 min</span> </h5>
                            <a href="details" className="order-part d-flex justify-content-between align-items-center">
                                <h5 className="dark-text p-0">ORDER NOW</h5>
                                <img className="img-fluid arrow" src="assets/images/svg/arrow.svg" alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="main-banner-box">
                        <img className="banner-img img-fluid w-100" src="assets/images/landing/banner8.png" alt="banner"/>
                        <div className="main-banner-details">
                            <h3>Food and where to find them !</h3>
                            <h5>Food deliver at your door:<span> 20 min</span> </h5>
                            <a href="details" className="order-part d-flex justify-content-between align-items-center">
                                <h5 className="dark-text p-0">ORDER NOW</h5>
                                <img className="img-fluid arrow" src="assets/images/svg/arrow.svg" alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="main-banner-box">
                        <img className="banner-img img-fluid w-100" src="assets/images/landing/banner9.png" alt="banner"/>
                        <div className="main-banner-details">
                            <h3>Grocery and where to find them !</h3>
                            <h5>Grocery deliver at your door:<span> 20 min</span> </h5>
                            <a href="details" className="order-part d-flex justify-content-between align-items-center">
                                <h5 className="dark-text p-0">ORDER NOW</h5>
                                <img className="img-fluid arrow" src="assets/images/svg/arrow.svg" alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- banner section end --> */}

    {/* <!-- seller product section starts --> */}
    <section className="section-b-space">
        <div className="custom-container">
            <div className="title">
                <h3 className="mt-0">Best Seller Product</h3>
                <a href="details">View All</a>
            </div>

            <div className="swiper main-seller-product">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="main-product-card">
                            <img className="card-img-top product-card-img" src="assets/images/landing/banner10.png"
                                alt="Card image" style={{width:"100%"}} />
                            <div className="card-body">
                                <div className="card-title">
                                    <h5>Immunity booster</h5>
                                    <div className="d-flex align-items-center gap-1">
                                        <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                        <h6 className="rate-color">4.5</h6>
                                    </div>
                                </div>
                                <h6 className="mt-1 light-text">| Buy 1 pack get 2 free</h6>

                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <h3 className="theme-color">$8 <del className="light-text">$15</del></h3>
                                    <a href="#" className="btn theme-btn add-btn mt-0 ">+ Add</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="main-product-card">
                            <img className="card-img-top product-card-img" src="assets/images/landing/banner11.png"
                                alt="Card image" style={{width:"100%"}} />
                            <div className="card-body">
                                <div className="card-title">
                                    <h5>Immunity booster</h5>
                                    <div className="d-flex align-items-center gap-1">
                                        <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                        <h6 className="rate-color">4.5</h6>
                                    </div>
                                </div>
                                <h6 className="mt-1 light-text">| Buy 1 pack get 2 free</h6>

                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <h3 className="theme-color">$8 <del className="light-text">$15</del></h3>
                                    <a href="#" className="btn theme-btn add-btn mt-0">+ Add</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- seller product section end --> */}

    {/* <!-- panel-space start --> */}
    <section className="panel-space"></section>
    {/* <!-- panel-space end --> */}

    {/* <!-- bottom navbar start --> */}
    <div className="navbar-menu">
        <ul>
            <li className="active">
                <a href="landing" className="icon logo">
                    <img className=" img-fluid" src="assets/images/svg/food-logo.svg" alt="logo" />
                    <span>Zomo</span>
                </a>
            </li>
            <li>
                <a href="food-home" className="icon">
                    <img className="unactive" src="assets/images/svg/food.svg" alt="food" />
                    <img className="active" src="assets/images/svg/food-fill.svg" alt="food" />
                    <span>Food</span>
                </a>
            </li>
            <li>
                <a href="grocery-home" className="icon">

                    <img className="unactive" src="assets/images/svg/grocery.svg" alt="grocery" />
                    <img className="active" src="assets/images/svg/grocery-fill.svg" alt="grocery" />
                    <span>Grocery</span>
                </a>
            </li>

            <li>
                <a href="pharmacy-home" className="icon">

                    <img className="unactive" src="assets/images/svg/pharmacy.svg" alt="pharmacy" />
                    <img className="active" src="assets/images/svg/pharmacy-fill.svg" alt="pharmacy" />
                    <span>Pharmacy</span>
                </a>
            </li>
            <li>
                <a href="profile" className="icon">

                    <img className="unactive" src="assets/images/svg/user.svg" alt="user" />
                    <img className="active" src="assets/images/svg/user-fill.svg" alt="user" />
                    <span>Profile</span>
                </a>
            </li>
        </ul>
    </div>
    {/* <!-- bottom navbar end --> */}

    </div>
  )
}

export default Landing