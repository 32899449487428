import React from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Footer({ category, changeCategory }) {
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.cart);

  const itemCount = () => {
    let count = 0
    if (cartProducts?.items?.length > 0) {
      cartProducts?.items.map((orderItem) => {
        if (orderItem?.addonProducts?.length > 0) {
          count += orderItem.addonProducts.length;
        } else {
          count += 1;
        }
      })
      return parseInt(count);
    } else {
      return count;
    }
  }

  const location = useLocation();
  const currentRoute = location.pathname;

  const home = () => {
    navigate("/home");
  }

  const cart = () => {
    navigate("/cart");
  }

  const profile = () => {
    navigate("/profile");
  }

  return (
    <div>
      <div class="navbar-menu">
        <ul>
          <li class={`${category == 'FOOD' ? 'active' : ''}`}>
            <a class="icon" onClick={() => {
              localStorage.setItem('category', 'FOOD')
              changeCategory("FOOD")
              navigate(currentRoute === '/home' ? null : "/home")
            }}>
              <img
                class="unactive"
                src="assets/images/svg/food.svg"
                alt="food"
              />
              <img
                class="active"
                src="assets/images/svg/food-fill.svg"
                alt="food"
              />
              <span>Food</span>
            </a>
          </li>
          <li class={`${category == 'GROCERY' ? 'active' : ''}`}>
            <a class="icon" onClick={() => {
              localStorage.setItem('category', 'GROCERY')
              changeCategory("GROCERY")
              navigate(currentRoute === '/home' ? null : "/home")
            }}>
              <img
                class="unactive"
                src="assets/images/svg/grocery.svg"
                alt="grocery"
              />
              <img
                class="active"
                src="assets/images/svg/grocery-fill.svg"
                alt="grocery"
              />
              <span>Grocery</span>
            </a>
          </li>
          <li class={`${category == 'CART' ? 'active position-relative' : 'position-relative'}`}>
            <a class="icon" onClick={() => { cart() }}>
              <img
                class="unactive"
                src="assets/images/svg/cart.svg"
                alt="user"
              />
              <img
                class="active"
                src="assets/images/svg/cart-fill.svg"
                alt="user"
              />
              <span>Cart</span>
            </a>
            {cartProducts?.items?.length > 0 ?
              <div className='position-absolute' style={{ top: '0px', right: "12px" }}>
                <Badge bg="danger" style={{ borderRadius: '50%', fontSize: '10px', color: '#fff' }}>{itemCount()}</Badge>
              </div> : null}
          </li>
          <li class={`${category == 'PROFILE' ? 'active' : ''}`}>
            <a class="icon" onClick={() => { profile() }}>
              <img
                class="unactive"
                src="assets/images/svg/user.svg"
                alt="user"
              />
              <img
                class="active"
                src="assets/images/svg/user-fill.svg"
                alt="user"
              />
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
