import React, { useState } from 'react'
import nonVeg from '../../../../assets/svg/nonveg.svg';
import veg from '../../../../assets/icons/veg.svg';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../../../config';
import no_image from '../../../../assets/images/rp1.png';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, addAddonsCart, removeAddonsCart } from '../../../../Redux/Store/cartReducer';
import Customization from '../../../Customization';

function ListItems({ product, store, onStateItemChange, onOtherStoreChange }) {
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart);

    return (
        <div class="product-box2 py-3">
            <div class="product-content">
                <img class="img" src={product?.food_type === "non-veg" ? nonVeg : veg} alt="non-veg" />
                <h5 class="product-name mt-1">{product?.name}</h5>
                <div class="product-price" style={{ display: 'flex', alignItems: 'center' }}>
                    {product['prices']['price'] > 0 &&
                        <>
                            {parseFloat(store?.offer_percentage) > 0 ? (
                                <h6 class="fw-semibold">
                                    <span> ₹{parseFloat(parseFloat(product.selling_price) - parseFloat(product.selling_price) * parseFloat(product?.shop.offer_percentage) / 100).toFixed(1)}</span>
                                    {" "}/  <s style={{ textDecorationColor: 'red' }}>₹{parseFloat(product.selling_price).toFixed(1)}</s>
                                </h6>
                            ) : parseFloat(product['prices']['orignal_price']) > parseFloat(product['prices']['price']) ? (
                                <h6 class="fw-semibold"><span>₹{parseFloat(product['prices']['price']).toFixed(1)}</span> /  <s style={{ textDecorationColor: 'red' }}>₹{parseFloat(product['prices']['orignal_price']).toFixed(1)}</s></h6>
                            ) : (
                                <h6 class="fw-semibold"><span>₹{parseFloat(product['prices']['price']).toFixed(1)}</span></h6>
                            )}
                        </>
                    }
                    {product?.addons?.length > 0 &&
                        <div style={{ marginLeft: '10px', color: 'grey !important' }}>Customizable</div>
                    }
                </div>
                <div class="ellipsis mb-0 mt-2 pt-2 w-100" style={{ fontSize: '12px' }}>
                    {product?.description ?? ""}
                </div>
            </div>
            <div class="product-img" >
                {/* <Link to={"/single-product/" + product?.id}> */}
                <ProgressiveImage
                    delay={20}
                    src={product?.images[0]?.url}
                    placeholder={no_image}
                >
                    {(src) => (
                        <img src={src}
                            className="img-fluid img"
                            style={{ width: '10rem', height: '7rem', objectFit: 'cover' }}
                            alt="deleevro"
                        />
                    )}
                </ProgressiveImage>
                {/* </Link> */}
                {product?.out_of_stock == 'YES' ? (
                    <div className='text-danger'>Out of Stock</div>
                ) : (
                    <>
                        {/* {product?.addons?.length > 0 ?
                            <Customization
                                floatCart={(e) => this.floatCart(e)}
                                product={product}
                                storeList={true}
                                shop={store}
                            />
                            :
                            <> */}
                        {cartProducts?.items?.find((cp) => cp.id === product?.id) !== undefined ? (
                            <>
                                {cartProducts?.items?.filter((cp) => cp.id === product?.id).map((item, index) => (
                                    <>
                                        {item?.addonProducts?.length > 0 ? (
                                            <div key={index} class="plus-minus" style={{ display: 'flex' }}>
                                                <i class="ri-subtract-line sub" onClick={() => {
                                                    let newData = Object.assign({}, item, { quantity: 1, removeAddonId: item?.addonProducts[0]?.id });
                                                    dispatch(removeAddonsCart(newData));
                                                }}></i>
                                                <input type="number" value={item.quantity} min="1" max="10" />
                                                <i class="ri-add-line add" onClick={() => {
                                                    let newData = Object.assign({}, item, { quantity: 1, addAddonId: item?.addonProducts[0]?.id });
                                                    dispatch(addAddonsCart(newData));
                                                }}></i>
                                            </div>
                                        ) : (
                                            <div key={index} class="plus-minus" style={{ display: 'flex' }}>
                                                <i class="ri-subtract-line sub" onClick={() => {
                                                    let newData = Object.assign({}, item, {
                                                        quantity: 1,
                                                    });
                                                    dispatch(removeFromCart(newData));
                                                }}></i>
                                                <input type="number" value={item.quantity} min="1" max="10" />
                                                <i class="ri-add-line add" onClick={() => {
                                                    let newData = Object.assign({}, item, {
                                                        price: product['prices']['orignal_price'],
                                                        selling_price: product['prices']['price'],
                                                        shop: store,
                                                        quantity: 1,
                                                        shop_id: cartProducts?.shop_id
                                                    });
                                                    dispatch(addToCart(newData));
                                                }} ></i>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </>
                        ) :
                            <>
                                {product?.addons?.length > 0 ? (
                                    <Customization
                                        floatCart={(e) => this.floatCart(e)}
                                        product={product}
                                        storeList={true}
                                        shop={store}
                                    />
                                ) : (
                                    <div class="add-btn" onClick={() => {
                                        let newData = Object.assign({}, product, {
                                            price: product['prices']['orignal_price'],
                                            selling_price: product['prices']['price'],
                                            shop: store,
                                            quantity: 1,
                                            image: product?.images[0]?.url
                                        });
                                        if (cartProducts?.items?.length === 0 || newData?.shop_id === cartProducts?.shop_id) {
                                            dispatch(addToCart(newData))
                                        } else {
                                            onOtherStoreChange(true);
                                            onStateItemChange(newData)
                                        }
                                    }}>
                                        <span class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal" style={{ borderRadius: '8px' }}><i
                                            class="ri-add-line"></i> ADD
                                        </span>
                                    </div>
                                )}
                            </>
                        }
                        {/* </>
                        } */}
                    </>
                )}
            </div>
        </div>
    )
}

export default ListItems