import { useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import Lottie from 'react-lottie';
import { Map, GoogleApiWrapper } from "google-maps-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import Bounce from "react-reveal/Bounce";
import Flip from "react-reveal/Flip";
import marker from "../../../assets/images/marker.png"
import load from "../../../assets/others/track-order-loading.json"
import { TbCurrentLocation } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const apiKey = 'AIzaSyDZpc98cMdmvs7PhzHeR9fxr70XWgKeRV4';

const defaultOptions = {
    animationData: load,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const LoadingContainer = (props) => (
    <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
        <Lottie
            options={defaultOptions}
            height={100}
            width={100}
        />
    </div>
)

const SetLocation = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [gpsLoading, setGpsLoading] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(true);
    const [googleScriptLoaded, setGoogleScriptLoaded] = useState(true);
    const [dragging, setDragging] = useState(false);
    const [mapCenter, setMapCenter] = useState([]);
    const [initialPosition, setInitialPosition] = useState([]);
    const [centerAroundCurrentLocation, setCenterAroundCurrentLocation] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem("userLat") && !localStorage.getItem("userLng")) {
            getMyLocation(() => {
                sessionStorage.setItem("is_location", "true")
                localStorage.setItem("is_location", "UPDATED");
                setLoading(false)
            })
        } else {
            setCurrentLocation(false)
            setLoading(false)
        }
        setMapCenter({
            lat: localStorage.getItem("userLat"),
            lng: localStorage.getItem("userLng"),
        })
        setInitialPosition({
            lat: localStorage.getItem("userLat"),
            lng: localStorage.getItem("userLng")
        })
    }, []);

    const handleGeoLocationClick = (results, type) => {
        setGpsLoading(true)
        const saveGeoLocation = new Promise((resolve) => {
            localStorage.setItem("geoLocation", JSON.stringify(results[0]));
            localStorage.setItem("address", results[0]?.formatted_address);
            setDefaultAddress(results[0].formatted_address);
            setTimeout(() => {
                if (type === 'normal') {
                    localStorage.setItem("userLat", results[0].geometry.location.lat);
                    localStorage.setItem("userLng", results[0].geometry.location.lng);
                    setMapCenter({
                        lat: results[0].geometry.location.lat,
                        lng: results[0].geometry.location.lng,
                    })
                } else {
                    localStorage.setItem("userLat", results[0].geometry.location.lat());
                    localStorage.setItem("userLng", results[0].geometry.location.lng());
                    setMapCenter({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                    })
                }
            }, 200);
            resolve("GeoLocation Saved");
        });

        saveGeoLocation.then(() => {
            setGpsLoading(false)
            setCurrentLocation(false)
            // window.location.reload()
            // navigate("/home");
        });
    };

    const getMyLocation = () => {
        const location = navigator && navigator.geolocation;
        if (window.ReactNativeWebView) {
            const response = {
                type: "location_permission",
                data: "no-data",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        setGpsLoading(true)
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    reverseLookup(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                }, (error) => {
                    setGpsLoading(true)
                    console.log(error);
                }
            );
        }
    };

    const reverseLookup = async (lat, lng) => {
        const formData = new FormData();
        formData.append("lng", lng);
        formData.append("lat", lat);
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
            );
            if (response.data.results.length > 0) {
                setDefaultAddress(response.data.results[0].formatted_address)
                const myLocation = [
                    { formatted_address: response.data.results[0].formatted_address, geometry: { location: { lat: lat, lng: lng, } } },
                ];
                setMapCenter({
                    lat: lat,
                    lng: lng,
                })
                localStorage.setItem("userLat", lat);
                localStorage.setItem("userLng", lng);
                handleGeoLocationClick(myLocation, 'normal');

                setLoading(false)
            } else {
                setDefaultAddress('No address found');
            }
        } catch (error) {
            console.error('Error during reverse geocoding:', error);
            setDefaultAddress('Error occurred');
        }

        setCenterAroundCurrentLocation(true)
    };

    const onMarkerDragEnd = (lat, lng) => {
        localStorage.setItem("userLat", lat);
        localStorage.setItem("userLng", lng);
        reverseLookup(lat, lng);
    };

    const handleDragging = () => {
        setDragging(true)
        localStorage.removeItem("firstTime")
    };

    const addNewAddress = () => {
        navigate("/add-address")
    };

    const defaultOptions = {
        animationData: load,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div>
            {loading || gpsLoading ? (
                <div className="d-flex flex-column justify-content-center align-items-center " style={{ paddingTop: "35vh" }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                    <span className="mt-3 fw-700 font-16" >
                        {gpsLoading ? "Location Service Is Off" : "Going to location..."}
                    </span>
                    {gpsLoading &&
                        <span className="mt-3 fw-700 font-16" >
                            Please Search Your Location
                        </span>
                    }
                </div>
            ) : (
                <Map
                    google={window.google}
                    initialCenter={{
                        lat: mapCenter.lat,
                        lng: mapCenter.lng,
                    }}
                    center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
                    zoom={15}
                    onDragend={(t, map) =>
                        onMarkerDragEnd(map.center.lat(), map.center.lng())
                    }
                    zoomControl={false}
                    mapTypeControl={false}
                    scaleControl={true}
                    streetViewControl={false}
                    fullscreenControl={false}
                    onReady={(mapProps, map) => {
                        dragging && (reverseLookup(initialPosition.lat, initialPosition.lng))
                        localStorage.setItem("userLat", map.center.lat());
                        localStorage.setItem("userLng", map.center.lng());
                    }}
                    onDragstart={() => { handleDragging(true); }}
                    centerAroundCurrentLocation={centerAroundCurrentLocation}
                >
                    <div className="center-marker-pulse" >
                        <Bounce top duration={1000}>
                            <img src={marker} alt="Marker" className="center-marker-full" />
                        </Bounce>
                    </div>
                </Map>
            )}

            <div className="w-100 p-2 top-0" style={{ position: 'fixed' }}>
                <div className="d-flex flex-row justify-content-between py-2 px-2 set-location-searchbar"
                    style={{ backgroundColor: "#fff", boxShadow: "rgb(167 167 167) 0px 0px 20px 2px" }} >
                    <div className='pt-1'>
                        <MdOutlineKeyboardArrowLeft
                            size={30}
                            color="#fad82c" onClick={() => navigate("/home")}
                        />
                    </div>
                    <div className="flex-grow-1 me-2 setlocation">
                        {googleScriptLoaded && (
                            <GooglePlacesAutocomplete
                                debounce={750}
                                withSessionToken={true}
                                loader={
                                    <div style={{ paddingTop: "35vh" }}>
                                        <Lottie
                                            options={defaultOptions}
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                }
                                renderInput={(props) => (
                                    <div className="input-location-icon-field">
                                        <input
                                            {...props}
                                            className="search-input-location"
                                            placeholder={"Search Your Location..."}
                                        />
                                    </div>
                                )}
                                renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                                    <div className="location-suggestions-container">
                                        {suggestions.map((suggestion, index) => (
                                            <Flip top
                                                delay={index * 50}
                                                key={suggestion.id}>

                                                <div className="location-suggestion"
                                                    onClick={(event) => {
                                                        onSelectSuggestion(suggestion, event);
                                                        geocodeByPlaceId(suggestion.place_id).then((results) => {
                                                            getLatLng(results[0]).then(({ lat, lng }) =>
                                                                setMapCenter({ lat: lat, lng: lng })
                                                            );
                                                            handleGeoLocationClick(results);
                                                        }).catch((error) => console.error(error));
                                                    }} >
                                                    <span className="location-main-name">
                                                        {suggestion.structured_formatting.main_text}
                                                    </span>
                                                    <br />
                                                    <span className=" ">
                                                        {suggestion.structured_formatting.secondary_text}
                                                    </span>
                                                </div>
                                            </Flip>
                                        ))}
                                    </div>
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
            {loading || gpsLoading ? ("") : (
                <div className="bottom-0 w-100 px-3 pb-3" style={{ position: 'fixed' }}>
                    {currentLocation ? (
                        <div className="" onClick={() => getMyLocation()} >
                            <div className="set-current-location-btn d-flex align-items-center justify-content-center py-3">
                                <TbCurrentLocation className='me-2'/> Use Current Location
                            </div>
                        </div>
                    ) : (
                        <div className='setlocation-address-type p-3'>
                            <div className="d-flex pt-3">
                                <span className="" style={{ fontWeight: "500", fontSize: "15px", }}>
                                    {localStorage.getItem("address")?.replace('"', "")}
                                </span>
                            </div>
                            <div className="pt-4 mb-3">
                                <button type="submit" className="set-location-btn py-3" onClick={() => { addNewAddress() }} >
                                    Add Address
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDZpc98cMdmvs7PhzHeR9fxr70XWgKeRV4",
    LoadingContainer: LoadingContainer
})(SetLocation);
