
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';
import { GoogleApiWrapper } from "google-maps-react";
import { getAllAddress, setAddressDefault } from '../../Redux/Store/addressReducer';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import Flip from "react-reveal/Flip";
import load from "../../assets/others/track-order-loading.json";
import axios from 'axios';

const apiKey = 'AIzaSyDZpc98cMdmvs7PhzHeR9fxr70XWgKeRV4';

const defaultOptions = {
    animationData: load,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const LoadingContainer = (props) => (
    <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
        <Lottie
            options={defaultOptions}
            height={100}
            width={100}
        />
    </div>
)

function Location() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [addresses, setAddresses] = useState([]);
    const [gpsLoading, setGpsLoading] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(true);
    const [mapCenter, setMapCenter] = useState([]);
    const [centerAroundCurrentLocation, setCenterAroundCurrentLocation] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState([]);

    const currentAddress = localStorage.getItem("currentAddress")?.replace(/"/g, '');

    const useCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    localStorage.setItem("userLat", latitude)
                    localStorage.setItem("userLng", longitude)
                    localStorage.setItem("address", currentAddress)
                    navigate("/add-address")
                },
                (error) => {
                    console.error("Error getting current location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        dispatch(getAllAddress()).then((response) => {
            if (response && response.payload && response.payload?.message == "success") {
                setAddresses(response?.payload?.result);
                // if (localStorage.getItem("IsAlready") === 'true') {
                //     localStorage.removeItem("IsAlready")
                //     navigate("/home")
                // } else {
                    setLoading(false)
                // }
            } else {
                setLoading(false)
            }
        })

        getMyLocation(() => {
            sessionStorage.setItem("is_location", "true")
            localStorage.setItem("is_location", "UPDATED");
            // setLoading(false)
        })
    }, []);

    const getMyLocation = (callback) => {
        const location = navigator && navigator.geolocation;
        if (window.ReactNativeWebView) {
            const response = {
                type: "location_permission",
                data: "no-data",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        setGpsLoading(true)
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    reverseLookup(
                        position.coords.latitude,
                        position.coords.longitude,
                        callback
                    );
                }, (error) => {
                    setGpsLoading(true)
                    console.log(error);
                }
            );
        }
    };

    const reverseLookup = async (latitude, longitude) => {
        var lat, lng;
        lat = latitude;
        lng = longitude;

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
            );
            if (response.data.results.length > 0) {
                localStorage.setItem("currentAddress", response.data.results[0].formatted_address);
                setDefaultAddress(response.data.results[0].formatted_address)
                // localStorage.setItem("userLat", lat);
                // localStorage.setItem("userLng", lng);
            } else {
                setDefaultAddress('No address found');
            }
        } catch (error) {
            console.error('Error during reverse geocoding:', error);
            setDefaultAddress('Error occurred');
        }

        setCenterAroundCurrentLocation(true)
    };

    const handleGeoLocationClick = (results) => {
        setGpsLoading(true)
        const saveGeoLocation = new Promise((resolve) => {
            localStorage.setItem("geoLocation", JSON.stringify(results[0]));
            localStorage.setItem("address", results[0]?.formatted_address);
            setDefaultAddress(results[0].formatted_address);
            setTimeout(() => {
                localStorage.setItem("userLat", results[0].geometry.location.lat());
                localStorage.setItem("userLng", results[0].geometry.location.lng());
                setMapCenter({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                })
            }, 200);
            resolve("GeoLocation Saved");
        });

        saveGeoLocation.then(() => {
            setGpsLoading(false)
            setCurrentLocation(false)
        });
    };

    const changeAddress = (e, clickAddress) => {
        e.preventDefault();
        setLoading(true)
        // const formData = new FormData();
        // formData.append("user_id", localStorage.getItem('user_id'));
        // formData.append("token", localStorage.getItem('device_token'));
        // formData.append("address_id", clickAddress.id);
        // dispatch(setAddressDefault(formData)).then((response) => {
        //     if (response && response.payload && response.payload.success) {
                const userSetAddress = {
                    latitude: clickAddress.latitude,
                    longitude: clickAddress.longitude,
                    address: clickAddress.map_address,
                    landmark: clickAddress.landmark,
                    tag: clickAddress.type,
                };
                localStorage.setItem("userLat", clickAddress.latitude);
                localStorage.setItem("userLng", clickAddress.longitude);
                localStorage.setItem("addressId", clickAddress.id);
                localStorage.setItem("address", clickAddress.address);
                localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
                // setAddresses(response?.payload?.data)
                if (localStorage.getItem("AddressEdit") == "true" || localStorage.getItem("AddressBack") == "true") {
                    localStorage.removeItem("AddressEdit")
                    localStorage.removeItem("AddressBack")
                    navigate("/manage-delivery-address")
                } else {
                    navigate("/home")
                }
                setLoading(false)
        //     } else {
        //         navigate("/home")
        //     }
        // })
    };

    const skipLocation = () => {
        if (localStorage.getItem("AddressEdit") == "true" || localStorage.getItem("AddressBack") == "true") {
            localStorage.removeItem("AddressEdit")
            localStorage.removeItem("AddressBack")
            navigate("/manage-delivery-address")
        } else {
            navigate("/home")
        }
    }
    return (
        <div style={{ minHeight: '100vh' }}>
            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :

                <div className="location-modal" id="location">
                    <div className="modal-dialog modal-fullscreen margin-none">
                        <div className="modal-content border-none">
                            <div className="position-fixed top-0 bg-white w-100" style={{ zIndex: '999' }}>
                                <div className="d-flex align-items-center">
                                    <div className="modal-title">
                                        <h2 className="fw-semibold">Select a Location</h2>
                                    </div>
                                </div>
                                <div className="custom-container" >
                                    <div className="form-group px-2" style={{ border: '1px solid #e8e8e8', borderRadius: '10px', boxShadow: '0px 0px 20px 0px #0000000d' }}>
                                        <div className="w-100 location">
                                            <GooglePlacesAutocomplete
                                                debounce={750}
                                                withSessionToken={true}
                                                selectProps={{
                                                    placeholder: 'Search Your Location...'
                                                }}

                                                loader={
                                                    <div style={{ paddingTop: "35vh" }}>
                                                        <Lottie
                                                            options={defaultOptions}
                                                            height={100}
                                                            width={100}
                                                        />
                                                    </div>
                                                }
                                                renderInput={(props) => (
                                                    <div className="form-input d-flex align-items-center w-100">
                                                        <i className="ri-search-line search-icon"></i>
                                                        <input
                                                            {...props}
                                                            type="search"
                                                            className="search-input-location"
                                                            placeholder={"Search Your Location..."}
                                                        />
                                                    </div>
                                                )}
                                                renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                                                    <div className="location-suggestions-container pb-2 px-2">
                                                        {suggestions.map((suggestion, index) => (
                                                            <Flip top
                                                                delay={index * 50}
                                                                key={suggestion.id}>
                                                                <hr style={{ color: '#e9e9e9', margin: '5px 5px' }} />
                                                                <div className="location-suggestion"
                                                                    onClick={(event) => {
                                                                        onSelectSuggestion(suggestion, event);
                                                                        geocodeByPlaceId(suggestion.place_id).then((results) => {
                                                                            getLatLng(results[0]).then(({ lat, lng }) => {
                                                                                setMapCenter({ lat: lat, lng: lng })
                                                                                localStorage.setItem("userLat", lat)
                                                                                localStorage.setItem("userLng", lng)
                                                                                navigate("/set-location")
                                                                            });
                                                                            handleGeoLocationClick(results);
                                                                        }).catch((error) => console.error(error));
                                                                    }} >
                                                                    <span className="location-main-name">
                                                                        {suggestion.structured_formatting.main_text}
                                                                    </span>
                                                                    <br />
                                                                    <span className=" ">
                                                                        {suggestion.structured_formatting.secondary_text}
                                                                    </span>
                                                                </div>

                                                            </Flip>
                                                        ))}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="custom-container" style={{ paddingTop: '7rem' }}>
                                <div className="current-location">
                                    <div className="current-address">
                                        <i className="ri-focus-3-line focus" style={{color:"#fad82c"}}></i>
                                        <div onClick={useCurrentLocation}>
                                            <h4 style={{color:"#fad82c"}}>Use current-location</h4>
                                            <h6>{currentAddress}</h6>
                                        </div>
                                    </div>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </div>
                                {addresses && addresses?.defaultAddress ?
                                    <>
                                        <h3 className="mt-3 fw-medium dark-text">Default Location</h3>

                                        <div className="recent-location">
                                            <div className="loation-icon text-center">
                                                {addresses?.defaultAddress?.address_type === "WORK" ? (
                                                    <i className="ri-briefcase-4-line"></i>
                                                ) : addresses?.defaultAddress?.address_type === "HOME" ? (
                                                    <i className="ri-home-5-line"></i>
                                                ) : addresses?.defaultAddress?.address_type === "HOTEL" ? (
                                                    <i className="ri-building-4-line"></i>
                                                ) : <i className="ri-map-pin-line"></i>}
                                            </div>

                                            <div className="recant-address">
                                                <h3>{addresses?.defaultAddress?.address_type}</h3>
                                                <h6>{addresses?.defaultAddress?.address}</h6>
                                            </div>
                                        </div>
                                    </>
                                    : null}
                                {addresses && addresses?.length > 0 ?
                                    <>
                                        <h3 className="mt-3 fw-medium dark-text">Addresses</h3>
                                        {addresses?.map((addrs) => (
                                            <div className="recent-location" onClick={(e) => { changeAddress(e, addrs) }}>
                                                <div className="loation-icon text-center">
                                                    {addrs?.type === "WORK" ? (
                                                        <i className="ri-briefcase-4-line"></i>
                                                    ) : addrs?.type === "HOME" ? (
                                                        <i className="ri-home-5-line"></i>
                                                    ) : addrs?.type === "HOTEL" ? (
                                                        <i className="ri-building-4-line"></i>
                                                    ) : <i className="ri-map-pin-line"></i>}
                                                </div>

                                                <div className="recant-address">
                                                    <h3>{addrs?.type}</h3>
                                                    <h6> {addrs?.map_address}</h6>
                                                    <h6> {addrs?.landmark}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </> : null}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {addresses && addresses?.defaultAddress ?
                <div className="position-fixed bottom-0 text-end px-3 py-2 bg-white" style={{ width: '100%' }}>
                    <div className="btn theme-btn px-3 w-100 mt-0" onClick={() => skipLocation()}>
                        {localStorage.getItem("AddressEdit") == "true" || localStorage.getItem("AddressBack") == "true" ?
                            "Go Back" : "Go Home"}
                    </div>
                </div>
                : null}
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDZpc98cMdmvs7PhzHeR9fxr70XWgKeRV4",
    LoadingContainer: LoadingContainer
})(Location);