import { useEffect, useRef, useState } from "react";
import { getAllSearchData } from "../../../Redux/Store/searchReducer";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import noSearchs from '../../../assets/others/noSearchItems.json'
import animationData from '../../../assets/others/track-order-loading.json'
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL } from "../../../config";
import no_image from '../../../assets/images/rp1.png'

function Search() {
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [items, setItems] = useState([]);
    const [input, setInput] = useState(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const searchDatas = (e) => {
        if (e.target.value.length > 2) {
            setLoading(true)
            dispatch(getAllSearchData({
                lat: localStorage.getItem('userLat'),
                long: localStorage.getItem('userLng'),
                name: e.target.value
            })).then((response) => {
                if (response && response.payload && response.payload?.message == 'success') {
                    setItems(response.payload?.result?.products)
                    setStores(response.payload?.result?.shops)
                    setLoading(false)
                } else {
                    console.log(response, 'error')
                }
            });
        } else {
            setItems([])
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const noSearch = {
        loop: true,
        autoplay: true,
        animationData: noSearchs,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div style={{ paddingBottom: '2rem' }}>
            <section className="search-section">
                <div className="custom-container d-flex align-items-center">
                    <div onClick={() => { window.history.back() }}>
                        <i class="ri-arrow-left-s-line fs-5"></i>
                    </div>
                    <form className="auth-form search-head flex-grow-1 ps-3" style={{ width: '100%' }} >
                        <div className="form-group" style={{ margin: '0px', width: '100%' }}>
                            <div className="form-input" style={{ width: '100%' }}>
                                <input type="search" className="form-control search" id="inputusername" ref={inputRef} placeholder="Restaurant, item & more" autoComplete="off"
                                    onChange={(e) => {
                                        searchDatas(e)
                                        setInput(e.target.value)
                                    }} />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :
                <div className="px-3 mt-2">
                    {input?.length > 2 && stores?.length > 0 || items?.length > 0 ? <>
                        {stores && stores?.length > 0 ? <>
                            <div className="mt-4 fs-5" style={{ fontWeight: '600' }}>
                                Stores
                            </div>
                            <Swiper slidesPerView={4} spaceBetween={10}>
                                {stores && stores?.map((shop) => (
                                    <SwiperSlide className='products' style={{ "borderRadius": "10px" }}>
                                        <div className="d-flex flex-column align-items-center justify-content-center"  >
                                            <Link to={"/single-store/" + shop?.id}>
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={shop?.avatar}
                                                    placeholder={no_image}
                                                >
                                                    {(src) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                height: '5rem',
                                                                width: '5rem',
                                                                objectFit: 'cover',
                                                                borderRadius: "50px"
                                                            }}
                                                            alt="deleevro"
                                                            className="img-fluid"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </Link>
                                            <h6 className="mt-1 custom-truncate" style={{ textAlign: 'center', fontWeight: '600', fontSize: '.8rem', width: '5rem' }}>
                                                {shop?.name}
                                            </h6>
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </> : null}

                        {items && items?.length > 0 ? <>

                            <div className="mt-4 fs-5" style={{ fontWeight: '600' }}>
                                Items
                            </div>
                            <div className="d-grid gap-2 hidden-scroll-bar pt-3 overflow-x-scroll" style={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
                                {items && items?.map((item) => (
                                    <div className='products' style={{ "borderRadius": "10px" }}>
                                        <div className="product-box product-box-bg bg-white p-0">
                                            <Link to={"/single-store/" + item?.shop?.id}>
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={IMAGE_BASE_URL + item.image}
                                                     placeholder={no_image}
                                                >
                                                    {(src) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                height: '7rem',
                                                                width: '100%',
                                                                objectFit: 'cover',
                                                                borderRadius: "10px 10px 0px 0px"
                                                            }}
                                                            alt="deleevro"
                                                            className="img-fluid"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </Link>
                                            <div className="product-box-detail" style={{ padding: '10px' }}>
                                                <h5 className="custom-truncate" style={{ width: '40vw' }}>{item.name}</h5>
                                                <ul className="timing custom-truncate" style={{ width: '35vw' }}>
                                                    {item?.shop?.name}
                                                </ul>
                                                <div className="bottom-panel mt-1">
                                                    {parseFloat(item?.shop?.offer_percentage) > 0 ? (
                                                        <div className='flex flex-col items-center'>
                                                            <div className="price me-1" style={{ lineHeight: '14px' }}>
                                                                ₹{parseFloat(parseFloat(item['prices']['price']) - parseFloat(item['prices']['price']) * parseFloat(item?.shop?.offer_percentage) / 100).toFixed(1)}
                                                            </div>
                                                            <div className="red-strike" style={{ fontSize: '13px', }}>
                                                                <s style={{ textDecorationColor: 'red' }}>
                                                                    ₹{parseFloat(item['prices']['price']).toFixed(1)}
                                                                </s>
                                                            </div>
                                                        </div>
                                                    ) : parseFloat(item['prices']['orignal_price']) > parseFloat(item['prices']['price']) ? (
                                                        <div className='flex flex-col items-center'>
                                                            <div className="price me-1" style={{ lineHeight: '14px' }}>
                                                                ₹{parseFloat(item['prices']['price']).toFixed(1)}
                                                            </div>
                                                            <div className="red-strike" style={{ fontSize: '13px' }}>
                                                                <s style={{ textDecorationColor: 'red' }}>
                                                                    ₹{parseFloat(item['prices']['orignal_price']).toFixed(1)}
                                                                </s>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="price">₹{parseFloat(item['prices']['price']).toFixed(1)}</div>
                                                    )}
                                                    <div className="cart mb-0">
                                                        <i className="ri-shopping-cart-2-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {item?.shop?.is_offer && parseFloat(item?.shop?.offer_percentage) > 0 ?
                                                <div style={{ backgroundColor: '#fbdb2c', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                                                    Save {parseInt(item?.shop?.offer_percentage)}%
                                                </div>
                                                : parseFloat(item['prices']['orignal_price']) > parseFloat(item['prices']['price']) &&
                                                <div style={{ backgroundColor: '#fbdb2c', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                                                    Save ₹{item['prices']['orignal_price'] - item['prices']['price']}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </> : null}
                    </> :
                        <div className="mt-5" style={{ fontSize: '14px' }}>
                            <div>
                                <Lottie
                                    options={noSearch}
                                    height={250}
                                    width={250}
                                />
                            </div>
                            <div className="mt-2 text-center" style={{ fontWeight: '600' }}>
                                No Restaurant and Items
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default Search