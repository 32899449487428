import React from 'react'
import { BsDot } from 'react-icons/bs';
import { MdStars } from 'react-icons/md';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL } from '../../../config';
import delivery from "../../../assets/svg/freeDelivery.svg";
import no_store from "../../../assets/images/no_store.png";
import no_image from '../../../assets/images/rp1.png'

function Restaurants({ category, stores }) {

  let foods = stores?.filter(store => store.store_type_id === 1) || null;
  let groceries = stores?.filter(store => store.store_type_id === 3) || null;
  let markets = stores?.filter(store => (store.store_type_id === 4 || store.store_type_id === 2)) || null;

  let restaurants = [];
  if (category === 'FOOD') {
    restaurants = foods;
  } else if (category === 'GROCERY') {
    restaurants = groceries;
  } else if (category === 'MEAT') {
    restaurants = markets;
  } else {
    restaurants = stores;
  }

  return (
    <div>
      {restaurants && restaurants.length > 0 ?
        <section className="section-t-space">
          <div className="" style={{ padding: '0px 15px' }}>
            <div className="title">
              <h3 className="mt-0">
                Explore Shops
              </h3>
            </div>
            {restaurants?.filter(cp => cp?.shopstatus == 'OPEN').map((store, index) => (
              <div key={index}>
                <div className="restaurants-cafe-box d-flex align-items-center position-relative mb-3 "  >
                  <Link to={`/single-store/${store.id}`} style={{ width: '40%' }}>
                    <ProgressiveImage
                      delay={20}
                      src={store.avatar}
                      placeholder={no_image}
                    >
                      {(src) => (
                        <img
                          src={src}
                          style={{
                            height: "10rem",
                            width: "100%",
                            borderRadius: "22px",
                            objectFit: "cover",
                            backgroundRepeat: "round",
                          }}
                          className=""
                          alt="zeato"
                        />
                      )}
                    </ProgressiveImage>
                  </Link>
                  <Link to={`/single-store/${store.id}`} style={{ width: "60%" }}>
                    <div
                      className="flex-grow-1 d-flex flex-column justify-content-center my-1 "
                      style={{ marginLeft: "12px", }}
                    >

                      <h5
                        className=" m-0 "
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "2",
                          overflow: "hidden",
                          color: "#09051C",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      > {store?.name ?? ""}
                      </h5>
                      <div
                        className=" d-flex flex-row align-items-center  font-semibold text-xl  mt-2"
                        style={{ fontSize: "16px", color: "black", fontWeight: "700" }}
                      >
                        <div className="d-flex flex-row align-items-center" >
                          <MdStars style={{ color: "#055438" }} size={24} />{" "}
                          {parseFloat(store?.rating) > 0 ? parseFloat(store?.rating).toFixed(1) : '1.0'}
                        </div>
                        {store?.estimated_delivery_time ?
                          <>
                            <div className="">
                              <BsDot style={{ color: "black" }} size={14} />
                            </div>
                            <div>
                              {store?.estimated_delivery_time}min
                            </div>
                          </>
                          : null}
                        {store?.distance ?
                          <>
                            <div className="">
                              <BsDot style={{ color: "black" }} size={14} />
                            </div>
                            <div
                              className=""
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: "2",
                                overflow: "hidden",
                                color: "#545454",
                                textAlign: "left",
                              }}
                            > {Math.ceil(store?.distance) ?? 0}km
                            </div>
                          </>
                          : null}
                      </div>

                      <div
                        className=""
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "2",
                          overflow: "hidden",
                          color: "#545454",
                          textAlign: "left",
                        }}
                      > {(store?.offer_promotion_ads && store?.offer_promotion_ads != '0') && store?.offer_promotion_ads}
                      </div>
                      {store?.free_delivery &&
                        <div className="flex flex-start">
                          <img src={delivery} style={{ height: "40px" }} />
                        </div>
                      }
                    </div>
                  </Link>
                </div>
              </div>
            ))}
            {restaurants?.filter(cp => cp?.shopstatus == 'CLOSED').map((store, index) => (
              <div key={index} className='shop-not-active'>
                <div className="restaurants-cafe-box d-flex align-items-center position-relative mb-3 "  >
                  <Link to={""} style={{ width: '40%' }}>
                    <ProgressiveImage
                      delay={20}
                      src={store.avatar}
                      placeholder={no_image}
                    >
                      {(src) => (
                        <img
                          src={src}
                          style={{
                            height: "10rem",
                            width: "100%",
                            borderRadius: "22px",
                            objectFit: "cover",
                            backgroundRepeat: "round",
                          }}
                          className=""
                          alt="zeato"
                        />
                      )}
                    </ProgressiveImage>
                  </Link>
                  <Link to={""} style={{ width: "60%" }}>
                    <div
                      className="flex-grow-1 d-flex flex-column justify-content-center my-1 "
                      style={{ marginLeft: "12px", }}
                    >

                      <h5
                        className=" m-0 "
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "2",
                          overflow: "hidden",
                          color: "#09051C",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      > {store?.name ?? ""}
                      </h5>
                      <div
                        className=" d-flex flex-row align-items-center  font-semibold text-xl  mt-2"
                        style={{ fontSize: "16px", color: "black", fontWeight: "700" }}
                      >
                        <div className="d-flex flex-row align-items-center" >
                          <MdStars style={{ color: "#055438" }} size={24} />{" "}
                          {parseFloat(store?.rating) > 0 ? parseFloat(store?.rating).toFixed(1) : '1.0'}
                        </div>
                        {store?.estimated_delivery_time ?
                          <>
                            <div className="">
                              <BsDot style={{ color: "black" }} size={14} />
                            </div>
                            <div>
                              {store?.estimated_delivery_time}min
                            </div>
                          </>
                          : null}
                        {store?.distance ?
                          <>
                            <div className="">
                              <BsDot style={{ color: "black" }} size={14} />
                            </div>
                            <div
                              className=""
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: "2",
                                overflow: "hidden",
                                color: "#545454",
                                textAlign: "left",
                              }}
                            > {Math.ceil(store?.distance) ?? 0}km
                            </div>
                          </>
                          : null}
                      </div>

                      <div
                        className=""
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "2",
                          overflow: "hidden",
                          color: "#545454",
                          textAlign: "left",
                        }}
                        > {store?.shopstatus == 'CLOSED' && <>{store?.shopopenstatus ?? ""}</>}
                      </div>
                      {store?.free_delivery ?
                        <div className="flex flex-start">
                          <img src={delivery} style={{ height: "40px" }} />
                        </div>
                        : null}
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
        : category == "MEAT" &&
        <div className="d-flex flex-column align-items-center justify-center mt-3" >
          <img src={no_store} alt='delevro' />
          <div style={{ fontWeight: '550' }}>
            No Stores Found
          </div>
        </div>
      }
    </div>
  )
}

export default Restaurants