import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getAllAddress, setAddressDefault, deleteAddress } from '../../../Redux/Store/addressReducer';
import Lottie from 'react-lottie';
import load from "../../../assets/others/track-order-loading.json";
import { Drawer } from '@material-ui/core';
import { RiCloseCircleLine } from 'react-icons/ri';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

function ManageDeliveryAddress() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [open, setOpen] = useState(false);
  const [addressId, setAddressId] = useState(null);

  useEffect(() => {
    localStorage.removeItem("AddressEdit")
    localStorage.removeItem("address_id")
    const formData = new FormData();
    formData.append("token", localStorage.getItem("device_token"));
    formData.append("user_id", localStorage.getItem("user_id"));
    dispatch(getAllAddress(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setAddresses(response?.payload?.data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }, []);


  const changeAddress = (e, clickAddress) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("token", localStorage.getItem('device_token'));
    formData.append("address_id", clickAddress.id);
    dispatch(setAddressDefault(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        const userSetAddress = {
          latitude: response.payload.data.defaultAddress.latitude,
          longitude: response.payload.data.defaultAddress.longitude,
          address: response.payload.data.defaultAddress.address,
          landmark: response.payload.data.defaultAddress.landmark,
          tag: response.payload.data.defaultAddress.address_type,
        };
        localStorage.setItem("userLat", response.payload.data.defaultAddress.latitude);
        localStorage.setItem("userLng", response.payload.data.defaultAddress.longitude);
        localStorage.setItem("address", response.payload.data.defaultAddress.address);
        localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
        setAddresses(response?.payload?.data)
        setLoading(false)
      }
    })
  };

  const _deleteAddress = (e, addressId) => {
    e.preventDefault();
    setLoading(true)
    setOpen(false)
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("token", localStorage.getItem('device_token'));
    formData.append("address_id", addressId);
    dispatch(deleteAddress(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        const userSetAddress = {
          latitude: response.payload.data.defaultAddress.latitude,
          longitude: response.payload.data.defaultAddress.longitude,
          address: response.payload.data.defaultAddress.address,
          landmark: response.payload.data.defaultAddress.landmark,
          tag: response.payload.data.defaultAddress.address_type,
        };
        localStorage.setItem("userLat", response.payload.data.defaultAddress.latitude);
        localStorage.setItem("userLng", response.payload.data.defaultAddress.longitude);
        localStorage.setItem("address", response.payload.data.defaultAddress.address);
        localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
        setAddresses(response?.payload?.data)
        setLoading(false)
        setAddressId(null)
      } else {
        setLoading(false)
        setAddressId(null)
      }
    })
  };

  const editAddress = (id) => {
    localStorage.setItem("AddressEdit", true)
    localStorage.setItem("address_id", id)
    window.location.href = ("/add-address");
  };
  
  const addAddress = () => {
    setLoading(true)
    localStorage.setItem("AddressBack", true) 
    window.location.href = ("/set-location");
  };

  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ minHeight: '100vh' }}>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div style={{ paddingBottom: '5rem' }}>

          <div className=" position-sticky top-0 px-3 bg-white py-3" style={{ zIndex: '999', boxShadow: '0px 0px 20px 5px #f3f3f3' }}>
            <div className="d-flex align-items-center">
              <Link to={'/profile'}>
                <i className="ri-arrow-left-s-line fs-4"></i>
              </Link>
              <h2 className='flex-grow-1 text-center fs-6' style={{ fontWeight: '500' }}>Delivery Address</h2>
            </div>
          </div>

          <section className="section-b-space pt-0 pt-2">
            <div className="custom-container">
              <ul className="address-list">
                {addresses && addresses?.defaultAddress ?
                  <li>
                    <div className="address-icon">
                      <span>
                        {addresses?.defaultAddress?.address_type === "WORK" ? (
                          <i className="ri-briefcase-4-line"></i>
                        ) : addresses?.defaultAddress?.address_type === "HOME" ? (
                          <i className="ri-home-5-line"></i>
                        ) : addresses?.defaultAddress?.address_type === "HOTEL" ? (
                          <i className="ri-building-4-line"></i>
                        ) : <i className="ri-map-pin-line"></i>}
                      </span>
                    </div>
                    <div className="address-content">
                      <div className="address-place">
                        <h5>{addresses?.defaultAddress?.address_type}</h5>
                        <div onClick={() => { editAddress(addresses?.defaultAddress?.id) }}>
                          <i className="ri-edit-line edit theme-color"></i>
                        </div>
                      </div>
                      <p>{addresses?.defaultAddress?.address}</p>
                      <div className="select-option w-100">
                        <div className="form-check">
                          <input name="radio" id="radio1" className="form-check-input" type="radio" value="" checked />
                          <label for="radio1" className="form-check-label">Make as a default</label>
                        </div>
                        {/* <div className="fw-semibold theme-color" onClick={() => { setOpen(true); setAddressId(addresses?.defaultAddress?.id) }}>Remove</div> */}
                      </div>
                    </div>
                  </li>
                  : null}
                {addresses?.addresses && addresses?.addresses?.length > 0 ? addresses?.addresses?.map((addrs) => (
                  <li>
                    <div className="address-icon" >
                      <span>
                        {addrs?.address_type === "WORK" ? (
                          <i className="ri-briefcase-4-line"></i>
                        ) : addrs?.address_type === "HOME" ? (
                          <i className="ri-home-5-line"></i>
                        ) : addrs?.address_type === "HOTEL" ? (
                          <i className="ri-building-4-line"></i>
                        ) : <i className="ri-map-pin-line"></i>}
                      </span>
                    </div>
                    <div className="address-content">
                      <div className="address-place">
                        <h5>{addrs?.address_type}</h5>
                        <div onClick={() => { editAddress(addrs?.id) }}>
                          <i className="ri-edit-line edit theme-color"></i>
                        </div>
                      </div>
                      <p>{addrs?.address}</p>
                      <div className="select-option w-100">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" onClick={(e) => { changeAddress(e, addrs) }} />
                          <label className="form-check-label">Make as a default</label>
                        </div>
                        <div className="fw-semibold theme-color" onClick={() => { setOpen(true); setAddressId(addrs.id) }}>Remove</div>
                      </div>
                    </div>
                  </li>
                )) : null}

              </ul>
            </div>
          </section>
          <div className='position-fixed bottom-0 bg-white px-3 w-100 py-2' style={{ zIndex: '999' }}>
            <div className='btn theme-btn w-100 mt-0' onClick={() => addAddress()}>Add Address</div>
          </div>
        </div>
      }

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => { setOpen(false); setAddressId(null) }}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={20}
      >
        <div className='p-3'>
          <div className='d-flex justify-content-end align-items-end'>
            <div onClick={() => { setOpen(false); setAddressId(null) }}>
              <RiCloseCircleLine size={25} />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <HiOutlineExclamationCircle size={95} color='red' />
            <div className='text-slate-500 fw-450 mt-1'>Are you sure to want delete this address !</div>
          </div>
        </div>
        <div className="px-3 py-3 pb-4 w-100 bg-white">
          <button type="submit" className="get-start-button" onClick={(e) => { _deleteAddress(e, addressId) }}>
            Delete
          </button>
        </div>
      </Drawer>
    </div>
  )
}

export default ManageDeliveryAddress