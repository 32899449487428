import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import userReducer from "./Store/userReducer";
import homeReducer from "./Store/homeReducer";
import orderReducer from "./Store/orderReducer";
import walletReducer from "./Store/walletReducer";
import cartReducer from "./Store/cartReducer";
import itemReducer from "./Store/itemReducer";
import storeReducer from "./Store/storeReducer";
import searchReducer from "./Store/searchReducer";
import addressReducer from "./Store/addressReducer";





const persistConfig = {
    key: "root", 
    version: 1,
    storage,
};

const reducers = combineReducers({
    user: userReducer,
    home: homeReducer,
    order:orderReducer,
    wallet:walletReducer,
    cart: cartReducer,
    item: itemReducer,
    store: storeReducer,
    search: searchReducer,
    address: addressReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // You can customize middleware options here
        immutableCheck: false,
      }),
  });

export const persistor = persistStore(store);
