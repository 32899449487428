import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import load from "../../assets/others/track-order-loading.json";
import { getUserProfile, logoutUser } from "../../Redux/Store/userReducer";
import { Drawer } from "@material-ui/core";
import { RiCloseCircleLine } from 'react-icons/ri';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import ProgressiveImage from "react-progressive-image";
import { FcDeleteDatabase } from "react-icons/fc";
import no_image from '../../assets/images/rp1.png'
import { useNavigate } from "react-router-dom";

function Profile() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const user = useSelector((state) => state.user.user_data);


  useEffect(() => {
    setLoading(true)
    dispatch(getUserProfile()).then((response) => {
      if (response && response.payload && response.payload?.message == "success") {
        setLoading(false)
      } else {
        setLoading(true)
        localStorage.removeItem("user_id")
        localStorage.removeItem("userLogin")
        localStorage.removeItem("auth_token")
        localStorage.removeItem("is_location")
        localStorage.removeItem("address")
        localStorage.removeItem("geoLocation")
        localStorage.removeItem("userSetAddress")
        localStorage.removeItem("userLng")
        localStorage.removeItem("userLat")
        dispatch(logoutUser())
        navigate("/login")
      }
    });
  }, []);

  const logoutUserData = () => {
    setLoading(true)
    localStorage.removeItem("user_id")
    localStorage.removeItem("userLogin")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("is_location")
    localStorage.removeItem("address")
    localStorage.removeItem("geoLocation")
    localStorage.removeItem("userSetAddress")
    localStorage.removeItem("userLng")
    localStorage.removeItem("userLat")
    dispatch(logoutUser())
    navigate("/login")
  }

  const handleCategory = () => {
  }

  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {loading ?
        <div style={{ paddingTop: "35vh" }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>

          <header class="section-t-space">
            <div class="custom-container">
              <div class="header-panel">
                <h2>Profile</h2>
              </div>
            </div>
          </header>

          <section>
            <div class="custom-container">
              <div class="profile-cover">
                <ProgressiveImage
                  delay={20}
                  src="assets/images/icons/profile.png"
                  // src={category?.image}
                  // src={IMAGE_BASE_URL + store.image}
                  placeholder={no_image}
                >
                  {(src) => (
                    <img
                      src={src}
                      alt="deleevro"
                      class="img-fluid profile-pic"
                    />
                  )}
                </ProgressiveImage>
              </div>
              <div class="profile-name">
                <h5>{user?.data?.name}</h5>
                <h6>{user?.data?.email}</h6>
              </div>
            </div>
          </section>

          <section>
            <div class="custom-container">
              <ul class="profile-list">
                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/profile.svg"
                        alt="profile"
                      />
                    </div>
                    <Link to={'/profile-setting'} class="profile-box-name">
                      <h5>Profile Setting</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/profile-location.svg"
                        alt="location"
                      />
                    </div>
                    <Link to={'/location'} class="profile-box-name">
                      <h5>Manage Delivery Address</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                {/* <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/whishlist.svg"
                        alt="wishlist"
                      />
                    </div>
                    <Link to={'/wishList'} class="profile-box-name">
                      <h5>My Wishlist</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li> */}
                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/my-order.svg"
                        alt="order"
                      />
                    </div>
                    <Link to={'/order-history'} class="profile-box-name">
                      <h5>My Orders History</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>

                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/ordertruck.svg"
                        alt="order"
                      />
                    </div>
                    <Link to={'/ongoing-orders'} class="profile-box-name">
                      <h5>Ongoing Orders</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                {/* <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/ticket.svg"
                        alt="ticket"
                      />
                    </div>
                    <Link to={'/list-coupons'} class="profile-box-name">
                      <h5>My Voucher</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li> */}
                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <img
                        class="img-fluid icon"
                        src="assets/images/svg/logout.svg"
                        alt="logout"
                      />
                    </div>
                    <div class="profile-box-name" onClick={() => { setLogoutOpen(true) }}>
                      <h5>Log Out</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="profile-box">
                    <div class="profile-icon">
                      <FcDeleteDatabase size={18} />
                    </div>
                    <div class="profile-box-name" onClick={() => { setDeleteOpen(true) }}>
                      <h5>Delete Account</h5>
                      <i class="ri-arrow-right-s-line arrow"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <section class="panel-space"></section>
          {logoutOpen || logoutOpen ? null :
            <Footer category={'PROFILE'} changeCategory={handleCategory} />
          }

          <Drawer
            anchor="bottom"
            open={logoutOpen}
            onClose={() => setLogoutOpen(false)}
            onOpen={() => setLogoutOpen(true)}
            swipeAreaWidth={20}
          >
            <div className='p-3'>
              <div className='d-flex justify-content-end align-items-end'>
                <div onClick={() => { setLogoutOpen(false) }}>
                  <RiCloseCircleLine size={25} />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <HiOutlineExclamationCircle size={95} color='#fbdb2c' />
                <div className='text-slate-500 fw-450 mt-1'>Are you sure to want logout !</div>
              </div>
            </div>
            <div className="px-3 py-3 pb-4 w-100 bg-white">
              <button type="submit" className="get-start-button" onClick={() => { logoutUserData() }}>
                Logout
              </button>
            </div>
          </Drawer>

          <Drawer
            anchor="bottom"
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            onOpen={() => setDeleteOpen(true)}
            swipeAreaWidth={20}
          >
            <div className='p-3'>
              <div className='d-flex justify-content-end align-items-end'>
                <div onClick={() => { setDeleteOpen(false) }}>
                  <RiCloseCircleLine size={25} />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <HiOutlineExclamationCircle size={95} color='red' />
                <div className='text-slate-500 fw-450 mt-1 text-center'>
                  Are you sure to want delete your account !
                  <br />
                  Re-login with your mobile no to recover your account.
                </div>
              </div>
            </div>
            <div className="px-3 py-3 pb-4 w-100 bg-white">
              <button type="submit" className="get-start-button" onClick={() => { logoutUserData() }}>
                Delete
              </button>
            </div>
          </Drawer>
        </div>
      }
    </div>
  );
}

export default Profile;
