import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { WEBSITE_API_URL, WEBSITE_API_URL_NEW } from "../../config";

const initialState = {
    items: [],
    appliedCoupon: [],
    shop_id: [],
    shop_info: [],
    shop_tax: [],
    coupon_valid: [],
    shop_distance: [],
    totalAmount: 0,
    tax: 0,
    deliveryCharge: 0,
    discount: 0,
    compelete_order: [],
    amountToBeReturned: 0,
    totalPayableAmount: 0,
    paymentMethod: "Cash",
};

export const applyCoupon = createAsyncThunk("shop/applyCoupon", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/apply-coupon", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});
export const Online = createAsyncThunk(
    "shop/Online",
    async (formData, { rejectWithValue }) => {
      const {
        calculateCharge,
        delivery_charge,
        delivery_lat,
        delivery_lng,
        deliveryTip,
        items,
        notes,
        payment_mode,
        promocode,
        shopId,
        user_address_id,
      } = formData;
  
      try {
        const response = await axios.post(
          `${WEBSITE_API_URL}/user/order`,
          {
            calculateCharge,
            delivery_charge,
            delivery_lat,
            delivery_lng,
            deliveryTip,
            items,
            notes,
            payment_mode,
            promocode,
            shopId,
            user_address_id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("device_token"),
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error while sending order:", error);
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );
  


export const placeOrder = createAsyncThunk("shop/placeOrder", async (formData) => {
    const { calculateCharge, delivery_charge, delivery_lat, delivery_lng, deliveryTip, items,
         notes, payment_mode, promocode, shopId, user_address_id } = formData;

    try {
        const response = await axios.post(
            `${WEBSITE_API_URL_NEW}/order`,
            {
                calculateCharge: calculateCharge,
                delivery_charge: delivery_charge,
                delivery_lat: delivery_lat,
                delivery_lng: delivery_lng,
                deliveryTip: deliveryTip,
                items: items,
                notes: notes,
                payment_mode: payment_mode,
                promocode: promocode,
                shopId: shopId,
                user_address_id: user_address_id
            },
            {
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});
export const getPaymentSucsess = createAsyncThunk("shop/getPaymentSucsess", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/user/order/{id}", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getShopDistance = createAsyncThunk("shop/getShopDistance", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-shop-distance", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getCartShop = createAsyncThunk("shop/getCartshop", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-cart-shop", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const checkCouponValid = createAsyncThunk("shop/checkCouponValid", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/check-coupon-valid", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const calcualteShopMaxDistance = createAsyncThunk("shop/calcualteShopMaxDistance", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/calcualte-shop-max-distance", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const calculateShopChargeTax = createAsyncThunk("shop/calculateShopChargeTax", async (formData) => {
    const { calculateCharge, delivery_lat, delivery_long, deliveryTip, items, promocode, shopId, user_address_id } = formData;
    
    try {
        const response = await axios.post(
            `${WEBSITE_API_URL_NEW}/verifyCart`,
            {
                calculateCharge: calculateCharge,
                delivery_lat: delivery_lat,
                delivery_long: delivery_long,
                deliveryTip: deliveryTip,
                items: items,
                promocode: promocode,
                shopId: shopId,
                user_address_id: user_address_id,
              
            },
            {
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getAllCartCoupons = createAsyncThunk("shop/getAllCartCoupons", async (formData) => {
    const { shop_id } = formData;

    try {
        const response = await axios.get(
            `${WEBSITE_API_URL_NEW}/promocodes`,
            {
                params: {
                    shop_id: shop_id
                },
                headers: {
                    'Authorization': localStorage.getItem('device_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('Unauthorized: Please check your credentials.');
            // Handle the 401 error appropriately here
        } else {
            console.log('error2', error);
        }
        throw error;
    }
});

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { items, totalAmount } = state;
            const { id, name, selling_price, shop, price, food_type, image, quantity, shop_id } = action.payload;
            const item = items.find((item) => item.id === id);
            let currentTotal = totalAmount;
            let itemDiscount = 0;
            if (item) {
                let sum = 0;
                item.quantity += 1;
                if (parseFloat(shop?.offer_percentage) > 0) {
                    const discountedPrice = parseFloat(parseFloat(selling_price) - parseFloat(selling_price) * parseFloat(shop?.offer_percentage) / 100).toFixed(1);
                    itemDiscount = parseFloat(selling_price) - discountedPrice;
                } else if (parseFloat(price) > parseFloat(selling_price)) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                } else {
                    itemDiscount = 0;
                }
                sum = parseFloat((item.selling_price * item.quantity).toFixed(2));
                item.totalPrice = sum;
                currentTotal = parseFloat(currentTotal) + parseFloat(selling_price);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            } else {
                let sum = 0;
                if (parseFloat(price) - parseFloat(selling_price) > 0) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                }

                if (parseFloat(shop?.offer_percentage) > 0) {
                    const discountedPrice = parseFloat(parseFloat(selling_price) - parseFloat(selling_price) * parseFloat(shop?.offer_percentage) / 100).toFixed(1);
                    itemDiscount = parseFloat(selling_price) - discountedPrice;
                    sum = parseFloat((parseFloat(parseFloat(selling_price) - parseFloat(itemDiscount)) * quantity).toFixed(2));
                } else if (parseFloat(price) > parseFloat(selling_price)) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                    sum = parseFloat((selling_price * quantity).toFixed(2));
                } else {
                    itemDiscount = 0;
                    sum = parseFloat((selling_price * quantity).toFixed(2));
                }

                items.push({
                    id: id,
                    itemId: id,
                    name: name,
                    price: price,
                    image: image,
                    food_type: food_type,
                    quantity: 1,
                    discount: itemDiscount,
                    selling_price: sum,
                });
                currentTotal = parseFloat(currentTotal) + parseFloat(sum);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            state.shop_id = shop_id;
            state.totalAmount = parseFloat(currentTotal);
            // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
        },
        removeFromCart: (state, action) => {
            const { items, totalAmount } = state;
            const { id, isRemoveAll } = action.payload;
            const item = items.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                if (isRemoveAll) {
                    currentTotal -= item.totalPrice;
                    const index = items.indexOf(item);
                    items.splice(index, 1);
                    if (items.length === 0) {
                        currentTotal = 0;
                    }
                    state.shop_id = [];
                    state.tax = 0;
                } else {
                    item.quantity -= 1;
                    let totalPrice = 0;
                    totalPrice = parseFloat((item.selling_price * item.quantity).toFixed(2));
                    item.totalPrice = totalPrice;
                    currentTotal -= item.selling_price;

                    if (item.quantity === 0) {
                        const index = items.indexOf(item);
                        items.splice(index, 1);
                        if (items.length === 0) {
                            currentTotal = 0;
                        }
                    }
                    // state.tax = parseFloat((currentTotal * (item.shop?.tax / 100)).toFixed(2));
                }
                state.totalAmount = parseFloat(currentTotal);
                // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
                state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            }
        },
        addCustomizationCart: (state, action) => {
            const { items, totalAmount } = state;
            const { id, name, shop_id, price, image, food_type, quantity, addonProducts } = action.payload;

            const item = items.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                let newPrice = 0;
                if (item.addonProducts && item.addonProducts.length > 0) {
                    item.addonProducts.map((addon) => {
                        const addon_selected = addonProducts.find((selAdon) => selAdon.id === addon.id)
                        const addon_not_selected = addonProducts.filter((selAdon) => selAdon.id !== addon.id)

                        if (addon_selected) {
                            addon.quantity += quantity;
                            addon.totalPrice = parseFloat(addon.totalPrice);
                            newPrice = quantity * parseFloat(addon.price);
                            const index = item.addonProducts.findIndex((p) => p.id === addon.id);
                            item.addonProducts[index] = { ...item.addonProducts[index], quantity: addon.quantity, totalPrice: addon.totalPrice }
                            currentTotal = parseFloat(currentTotal) + newPrice;
                        }
                        if (addon_not_selected) {
                            addon_not_selected.map((addon_not) => {
                                if (addon_not.id !== addon.id) {
                                    const index = item.addonProducts.findIndex((cp) => cp.id === addon_not.id)
                                    if (index == -1) {
                                        let totalPrice = parseFloat(addon_not.totalPrice);
                                        item.addonProducts.push(addon_not);
                                        currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                                        // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
                                    }
                                }
                            })
                        }
                    })
                } else {
                    let totalPrice = 0;
                    totalPrice += addonProducts.reduce((AddonPrice, p) => {
                        AddonPrice += parseFloat(p.totalPrice);
                        return (AddonPrice)
                    }, 0);
                    items.push({
                        id: id,
                        itemId: id,
                        name: name,
                        price: price,
                        image: image,
                        addonProducts: addonProducts,
                        food_type: food_type
                    });
                    currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                    // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
                }
            } else {
                let totalPrice = 0;
                totalPrice += addonProducts.reduce((AddonPrice, p) => {
                    AddonPrice += parseFloat(p.totalPrice);
                    return (AddonPrice)
                }, 0);
                items.push({
                    id: id,
                    itemId: id,
                    name: name,
                    price: price,
                    image: image,
                    addonProducts: addonProducts,
                    food_type: food_type,
                    quantity: quantity
                });
                currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            state.shop_id = shop_id;
            state.totalAmount = parseFloat(currentTotal);
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
        },
        addAddonsCart: (state, action) => {
            const { items, totalAmount } = state;
            const { id, addAddonId } = action.payload;
            const item = items.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                if (item.addonProducts && item.addonProducts.length > 0) {
                    item.addonProducts.map((addon) => {
                        if (addon.id == addAddonId) {
                            addon.quantity += 1;
                            item.quantity += 1;
                            addon.totalPrice = parseFloat(addon.totalPrice) + parseFloat(addon.price) + parseFloat(item.price);
                            const index = item.addonProducts.findIndex((p) => p.id === addon.id);
                            item.addonProducts[index] = { ...item.addonProducts[index], quantity: addon.quantity, totalPrice: addon.totalPrice }
                            currentTotal = parseFloat(currentTotal) + parseFloat(addon.price) + parseFloat(item.price);
                        }
                    })
                }
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            // state.shop_id = shop.id;
            state.totalAmount = parseFloat(currentTotal);
            // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
        },

        removeAddonsCart: (state, action) => {
            const { items, totalAmount } = state;
            const { id, isRemoveAll, removeAddonId } = action.payload;
            // console.log(action)
            const item = items.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                let totalAddonPrice = 0
                if (item.addonProducts && item.addonProducts.length > 0) {
                    item.addonProducts.map((addon) => {
                        if (addon.id == removeAddonId) {
                            if (isRemoveAll) {
                                currentTotal -= addon.totalPrice;
                                const index = item.addonProducts.indexOf(addon);
                                item.addonProducts.splice(index, 1);
                                if (item.addonProducts.length === 0) {
                                    const itemIndex = items.indexOf(item);
                                    items.splice(itemIndex, 1);
                                    if (items.length === 0) {
                                        currentTotal = 0;
                                    }
                                }
                                state.totalAmount = parseFloat(currentTotal);
                            } else {
                                addon.quantity -= 1;
                                item.quantity -= 1;
                                addon.totalPrice = parseFloat(addon.totalPrice) - parseFloat(addon.price) - parseFloat(item.price);
                                const index = item.addonProducts.findIndex((p) => p.id === removeAddonId);
                                item.addonProducts[index] = {
                                    ...item.addonProducts[index],
                                    quantity: addon.quantity,
                                    totalPrice: addon.totalPrice
                                };
                                currentTotal = parseFloat(currentTotal) - parseFloat(addon.price) - parseFloat(item.price);
                                if (addon.quantity === 0) {
                                    item.addonProducts.splice(index, 1);
                                    if (item.addonProducts.length === 0) {
                                        const itemIndex = items.indexOf(item);
                                        items.splice(itemIndex, 1);
                                        if (items.length === 0) {
                                            currentTotal = 0;
                                            state.store_id = [];
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
                state.totalAmount = parseFloat(currentTotal);
                state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            }
        },
        clearCart: (state) => {
            state.items = [];
            state.totalAmount = 0;
            state.tax = 0;
            state.discount = 0;
            state.amountToBeReturned = 0;
            state.totalPayableAmount = 0;
            state.paymentMethod = "Cash";
            state.shop_id = null;
            state.appliedCoupon = [];
            state.shop_info = [];
            state.shop_tax = [];
            state.shop_distance = [];
            state.deliveryCharge = 0;
            state.compelete_order = [];
        },
        clearCoupon: (state) => {
            state.appliedCoupon = null;
            state.discount = 0;
        },
        updateTotalAmount: (state, action) => {
            state.totalAmount = action.payload;
        },

        setDiscount: (state, action) => {
            state.discount = action.payload;
        },
        setAmountToBeReturned: (state, action) => {
            state.amountToBeReturned = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        setSingleItemDiscount: (state, action) => {
            const { items, totalAmount, totalPayableAmount } = state;
            const { item_id, price } = action.payload;
            let currentTotal = totalAmount;
            const item = items.find((item) => item.id === item_id);
            if (item) {
                let oldTotal = totalAmount;
                currentTotal = parseFloat(currentTotal) - parseFloat(item.price) + parseFloat(price);
                item.price = price;
                item.totalPrice = (price * item.quantity).toFixed(2);
                // alert(currentTotal)
                state.tax = parseFloat((currentTotal * 0.15).toFixed(2));
                state.totalPayableAmount = parseFloat(
                    (currentTotal + state.tax - state.discount).toFixed(2)
                );
            }
        },

        setWholeCartDiscount: (state, action) => {
            const { discountType, discountAmount } = action.payload;
            // const { discount } = state;
            if (discountType == "fixed") {
                state.totalPayableAmount = (state.totalAmount + state.tax) - discountAmount;
            } else {
                const toReduce = (
                    (state.totalPayableAmount * discountAmount) /
                    100
                ).toFixed(2);
                state.totalPayableAmount = (state.totalAmount + state.tax) - toReduce;
            }
            state.discount = discountAmount;
            // console.log(action.payload);
            // state.totalPayableAmount = action.payload;
        },

        setSelectedAddon: (state, action) => {
            const { items, totalAmount } = state;
            const { item_id, selectedAddon } = action.payload;

            const item = items.find((item) => item.id === item_id);

            if (item) {
                let price = item.price;

                if (item.selectedAddon && item.selectedAddon?.id != selectedAddon.id) {
                    price = parseFloat(price) - parseFloat(item.selectedAddon.price);
                    item.price = price;
                }
                item.selectedAddon = selectedAddon;

                item.price = (
                    parseFloat(price) + parseFloat(selectedAddon.price)
                ).toFixed(2);
            }
        },

        setSelectedModifier: (state, action) => {
            const { items, totalAmount } = state;
            const { item_id, selectedModifier } = action.payload;

            const item = items.find((item) => item.id === item_id);

            if (item) {
                let price = item.price;

                if (item.selectedModifier && item.selectedModifier?.id != selectedModifier.id) {
                    price = parseFloat(price) - parseFloat(item.selectedModifier.price);
                    item.price = price;
                }
                item.selectedModifier = selectedModifier;

                item.price = (
                    parseFloat(price) + parseFloat(selectedModifier.price)
                ).toFixed(2);
            }
        },
        addFromHoldCart: (state, action) => {
            const { data } = action.payload;
            state.items = data.items;
            state.totalAmount = data.totalAmount;
            state.tax = data.tax;
            state.discount = data.discount;
            state.amountToBeReturned = data.amountToBeReturned;
            state.totalPayableAmount = data.totalPayableAmount;
            state.paymentMethod = data.paymentMethod;
        },
    },

    extraReducers: {
        [applyCoupon.pending]: (state,) => {
            state.loading = true;
        },
        [applyCoupon.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (payload.success) {
                state.appliedCoupon = payload;
                state.discount = payload.used_amount;
            } else {
                state.appliedCoupon = null;
                state.discount = 0;
            }
            state.isSuccess = true;
        },
        [applyCoupon.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [placeOrder.pending]: (state,) => {
            state.loading = true;
        },
        [placeOrder.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.compelete_order = payload;
            state.isSuccess = true;
        },
        [placeOrder.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [getShopDistance.pending]: (state,) => {
            state.loading = true;
        },
        [getShopDistance.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_distance = payload;
            state.isSuccess = true;
        },
        [getShopDistance.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [getCartShop.pending]: (state,) => {
            state.loading = true;
        },
        [getCartShop.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_info = payload;
            state.isSuccess = true;
        },
        [getCartShop.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [checkCouponValid.pending]: (state,) => {
            state.loading = true;
        },
        [checkCouponValid.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.coupon_valid = payload;
            state.isSuccess = true;
        },
        [checkCouponValid.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [calcualteShopMaxDistance.pending]: (state,) => {
            state.loading = true;
        },
        [calcualteShopMaxDistance.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_distance = payload;
            if (payload?.shop_id?.delivery_charge_type === "DYNAMIC" && payload?.shop_id?.base_delivery_distance && payload?.shop_id?.extra_delivery_distance && payload?.shop_id?.extra_delivery_charge && payload?.shop_id?.base_delivery_charge) {
                const distanceFromUserToshop = payload.distance;
                if (distanceFromUserToshop > parseInt(payload?.shop_id?.base_delivery_distance)) {
                    const extraDistance = distanceFromUserToshop - parseInt(payload?.shop_id?.base_delivery_distance);
                    const extraCharge = (extraDistance / parseInt(payload?.shop_id?.extra_delivery_distance)) * parseFloat(payload?.shop_id?.extra_delivery_charge);
                    let dynamicDeliveryCharge = parseFloat(payload?.shop_id?.base_delivery_charge) + parseFloat(extraCharge);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
                    state.deliveryCharge = dynamicDeliveryCharge;
                } else {
                    state.deliveryCharge = parseFloat(payload?.shop_id?.base_delivery_charge);
                }
            } else if (payload?.shop_id?.delivery_charge_type === "FIXED" && parseFloat(payload?.shop_id?.delivery_charge) > 0) {
                state.deliveryCharge = payload?.shop_id?.delivery_charge;
            } else if (payload?.shop_id?.zone?.delivery_charge_type === "DYNAMIC" && payload?.shop_id?.zone?.base_delivery_distance && payload?.shop_id?.zone?.extra_delivery_distance && payload?.shop_id?.zone?.extra_delivery_charge && payload?.shop_id?.zone?.base_delivery_charge) {
                const distanceFromUserToshop = payload.distance;
                if (distanceFromUserToshop > parseInt(payload?.shop_id?.zone?.base_delivery_distance)) {
                    const extraDistance = distanceFromUserToshop - parseInt(payload?.shop_id?.zone?.base_delivery_distance);
                    const extraCharge = (extraDistance / parseInt(payload?.shop_id?.zone?.extra_delivery_distance)) * parseFloat(payload?.shop_id?.zone?.extra_delivery_charge);
                    let dynamicDeliveryCharge = parseFloat(payload?.shop_id?.zone?.base_delivery_charge) + parseFloat(extraCharge);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
                    state.deliveryCharge = dynamicDeliveryCharge;
                } else {
                    state.deliveryCharge = parseFloat(payload?.shop_id?.zone?.base_delivery_charge);
                }
            } else if (payload?.shop_id?.zone?.delivery_charge_type === "FIXED" && parseFloat(payload?.shop_id?.zone?.delivery_charge) > 0) {
                state.deliveryCharge = payload?.shop_id?.zone?.delivery_charge;
            }
            state.isSuccess = true;
        },
        [calcualteShopMaxDistance.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [calculateShopChargeTax.pending]: (state,) => {
            state.loading = true;
        },
        [calculateShopChargeTax.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_tax = payload;
            state.isSuccess = true;
        },
        [calculateShopChargeTax.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    }
});

export const {
    addToCart,
    addCustomizationCart,
    addAddonsCart,
    removeFromCart,
    removeAddonsCart,
    setDiscount,
    setAmountToBeReturned,
    setPaymentMethod,
    clearCart,
    clearCoupon,
    updateTotalAmount,
    setSelectedAddon,
    setSingleItemDiscount,
    setWholeCartDiscount,
    addFromHoldCart,
    setSelectedModifier
} = cartSlice.actions;

export default cartSlice.reducer;