import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const ApplyCoupon = ({ cartData, calculateCharges, setDiscount, discount }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [couponError, setCouponError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const cartProducts = useSelector((state) => state.cart);

    const viewCoupons = () => {
        navigate("/list-coupons");
    }

    const removeCoupon = () => {
        setDiscount(0);
        localStorage.removeItem('coupon');
        localStorage.removeItem('couponId');
        calculateCharges();
    }

    return (
        <div>
            {(cartData?.promocode?.status == 'success' && discount > 0) ?
                <div class="custom-container mt-3">
                    <div className='applied-coupon'>
                        <div>
                            <h5 class="dark-text cart-coupon-text">Applid Coupon</h5>
                            <h6 class="coupon-code cart-coupon-text mt-2">
                                {`Discount ${"₹" + parseFloat(cartData?.promocode?.discount)} applied`}
                            </h6>
                        </div>
                        <div className='flex items-center'>
                            <div className='px-2 cart-item-remove-round-btn' onClick={() => { removeCoupon() }}>
                                Remove
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div class="custom-container mt-3">
                    <div class="apply-coupon" onClick={() => { viewCoupons() }}>
                        <div>
                            <h5 class="dark-text">Apply Coupon</h5>
                            <h6 class="coupon-code"> </h6>
                        </div>
                        <i class="ri-arrow-right-s-line"></i>
                    </div>
                </div>
            }
            {couponError && <div className="text-danger text-center">{errorMessage}</div>}

        </div>
    )
}

export default ApplyCoupon
