import React from "react";
import { Link } from "react-router-dom";

function WishList() {
  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to={'/profile'}>
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>My Wishlist</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- wishlist start --> */}
      <section className="section-t-space section-b-space">
        <div className="custom-container">
          <div className="row gy-4">
            <div className="col-12">
              <div className="vertical-product-box">
                <div className="vertical-box-img">
                  <a href="details">
                    <img
                      className="img-fluid img" style={{ height: '41vw', width: '36vw', objectFit: 'cover' }}
                      src="assets/images/product/vp3.png"
                      alt="vp5"
                    />
                  </a>
                  <div className="like-icon">
                    <i className="ri-heart-3-line outline-icon"></i>
                    <i className="ri-heart-3-fill fill-icon"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>
                <div className="vertical-box-details">
                  <div className="vertical-box-head">
                    <div className="restaurant">
                      <a href="details">
                        <h5>AWS - USA</h5>
                      </a>
                      <h6>
                        <span className="award">
                          <i className="ri-award-fill"></i>
                        </span>{" "}
                        Best Seller
                      </h6>
                    </div>
                    <h6 className="food-items">classNameic bacon & eggs, sausage...</h6>

                    <h6 className="rating-star">
                      <span className="star">
                        <i className="ri-star-s-fill"></i>
                      </span>{" "}
                      4.0 (20k+ Reviews)
                    </h6>
                  </div>
                  <div className="location-distance">
                    <div className="location">
                      <h6 className="place">New York</h6>
                      <h6 className="pt-2 pb-2">
                        <span className="theme-color">$3 </span> for one
                      </h6>
                    </div>
                    <div className="distance">
                      <ul>
                        <li>
                          <i className="ri-map-pin-fill theme-color"></i> 2.5 km
                        </li>
                        <li>
                          <i className="ri-time-fill theme-color"></i> 35 min
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="vertical-product-box">
                <div className="vertical-box-img">
                  <a href="details">
                    <img
                      className="img-fluid img" style={{ height: '41vw', width: '36vw', objectFit: 'cover' }}
                      src="assets/images/product/vp4.png"
                      alt="vp5"
                    />
                  </a>
                  <div className="like-icon">
                    <i className="ri-heart-3-line outline-icon"></i>
                    <i className="ri-heart-3-fill fill-icon"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                </div>

                <div className="vertical-box-details">
                  <div className="vertical-box-head">
                    <div className="restaurant">
                      <a href="details">
                        <h5>Cactus Club Cafe</h5>
                      </a>
                    </div>
                    <h6 className="food-items">Spicy ahi roll, prawn crunch..</h6>

                    <h6 className="rating-star">
                      <span className="star">
                        <i className="ri-star-s-fill"></i>
                      </span>{" "}
                      5.0 (300k+ Reviews)
                    </h6>
                  </div>
                  <div className="location-distance">
                    <div className="location">
                      <h6 className="place">Victoria</h6>
                      <h6 className="pt-2 pb-2">
                        <span className="theme-color">$6</span> for one
                      </h6>
                    </div>
                    <div className="distance">
                      <ul>
                        <li>
                          <i className="ri-map-pin-fill theme-color"></i> 2.6 km
                        </li>
                        <li>
                          <i className="ri-time-fill theme-color"></i> 20 min
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="vertical-product-box">
                <div className="vertical-box-img">
                  <a href="details">
                    <img
                      className="img-fluid img" style={{ height: '41vw', width: '36vw', objectFit: 'cover' }}
                      src="assets/images/product/vp5.png"
                      alt="vp5"
                    />
                  </a>
                  <div className="like-icon">
                    <i className="ri-heart-3-line outline-icon"></i>
                    <i className="ri-heart-3-fill fill-icon"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                  <div className="offers">
                    <h6>upto $20</h6>
                    <h2>50% OFF</h2>
                  </div>
                </div>
                <div className="vertical-box-details">
                  <div className="vertical-box-head">
                    <div className="restaurant">
                      <a href="details">
                        <h5>Moxies Cafe</h5>
                      </a>
                      <h6>
                        <span className="award">
                          <i className="ri-award-fill"></i>
                        </span>{" "}
                        Best Seller
                      </h6>
                    </div>
                    <h6 className="food-items">
                      Crispy bottom vegan potsticker...
                    </h6>

                    <h6 className="rating-star">
                      <span className="star">
                        <i className="ri-star-s-fill"></i>
                      </span>{" "}
                      4.3 (50k+ Reviews)
                    </h6>
                  </div>
                  <div className="location-distance">
                    <div className="location">
                      <h6 className="place">Toronto</h6>
                      <h6 className="pt-2 pb-2">
                        <span className="theme-color">$5</span> for one
                      </h6>
                    </div>
                    <div className="distance">
                      <ul>
                        <li>
                          <i className="ri-map-pin-fill theme-color"></i>1 km
                        </li>
                        <li>
                          <i className="ri-time-fill theme-color"></i>15 min
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- wishlist start --> */}

      {/* <!-- bootstrap js --> */}
      <script src="assets/js/bootstrap.bundle.min.js"></script>

      {/* <!-- script js --> */}
      <script src="assets/js/script.js"></script>

      <script>
        {/* <!-- font link --> */}
        <link rel="stylesheet" href="assets/css/vendors/metropolis.min.css" />

        {/* <!-- remixicon css --> */}
        <link
          rel="stylesheet"
          type="text/css"
          href="assets/css/vendors/remixicon.css"
        />

        {/* <!-- bootstrap css --> */}
        <link
          rel="stylesheet"
          id="rtl-link"
          type="text/css"
          href="assets/css/vendors/bootstrap.min.css"
        />

        {/* <!-- Theme css --> */}
        <link
          rel="stylesheet"
          id="change-link"
          type="text/css"
          href="assets/css/style.css"
        />
      </script>
    </div>
  );
}

export default WishList;
