import React from 'react'

function Productlist() {
  return (
    <div> <header class="section-t-space">
    <div class="custom-container">
      <div class="header-panel">
        <a href="home">
          <i class="ri-arrow-left-s-line"></i>
        </a>
        <h2>Food Categories</h2>
      </div>
    </div>
  </header>
  <section class="products section-t-space section-b-space">
    <div class="custom-container">
      <div class="row gy-4">
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p1.png" alt="p1" />
            </a>
            <div class="product-box-detail">
              <h5>Chicken karahi</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 3.7
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$19.23</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p2.png" alt="p2" />
            </a>
            <div class="product-box-detail">
              <h5>Mexican Pizza</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.5
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$9.5</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p3.png" alt="p3" />
            </a>
            <div class="product-box-detail">
              <h5>Maggie Cheese</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.0
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$3</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p4.png" alt="p4" />
            </a>
            <div class="product-box-detail">
              <h5>Yam Fries</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 5.0
                </li>
                <li>10 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$9.50</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p5.png" alt="p5" />
            </a>
            <div class="product-box-detail">
              <h5>Mexican Pizza</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.5
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$9.5</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p6.png" alt="p6" />
            </a>
            <div class="product-box-detail">
              <h5>Maggie Cheese</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.0
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$3</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p7.png" alt="p7" />
            </a>
            <div class="product-box-detail">
              <h5>Mexican Pizza</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.5
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$9.5</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-4">
          <div class="product-box product-box-bg">
            <a href="details" class="product-box-img">
              <img class="img-fluid" src="assets/images/product/p8.png" alt="p8" />
            </a>
            <div class="product-box-detail">
              <h5>Maggie Cheese</h5>
              <ul class="timing">
                <li>
                  <span class="star"><i class="ri-star-s-fill"></i></span> 4.0
                </li>
                <li>20 min</li>
              </ul>
              <div class="bottom-panel">
                <div class="price">$3</div>
                <a href="#" class="cart mb-0">
                  <i class="ri-shopping-cart-2-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section></div>
  )
}

export default Productlist