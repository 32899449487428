import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllOrders, getOngoingOrders } from "../../../Redux/Store/orderReducer";
import ProgressiveImage from 'react-progressive-image';
import no_orders from '../../../assets/others/noorders.json'
import animationData from '../../../assets/others/track-order-loading.json'
import Lottie from "react-lottie";
import no_image from '../../../assets/images/rp1.png';
import axios from 'axios';

function OngoingOrders() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)

  const orders = useSelector((state) => state.order?.order_data?.result);
  const ongoing_orders = useSelector((state) => state.order?.ongoing_order?.result);
  console.log('ongoing_orders:', orders);
  

  useEffect(() => {
    dispatch(getAllOrders()).then((response) => {
      setLoading(false)
    })

    fetchOrders();
    const intervalId = setInterval(fetchOrders, 10000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const fetchOrders = async () => {
    try {
      await dispatch(getOngoingOrders());
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (orderId) => {
    try {
      const response = await axios.delete(`http://deleevro.com/api/user/order/${orderId}`, {
        headers: {
          'Authorization': localStorage.getItem('device_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      console.log('Order deleted successfully');
      await fetchOrders();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isButtonVisible = (createdAt) => {
    const createdTime = new Date(createdAt);
    const currentTime = new Date();
    const timeDifference = (currentTime - createdTime) / (1000 * 60);
    return timeDifference <= 5;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const noOrders = {
    loop: true,
    autoplay: true,
    animationData: no_orders,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      {loading ?
        <div className='' style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div> :
        <div style={{ paddingBottom: '3rem' }}>

          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                <Link to={'/profile'}>
                  <i className="ri-arrow-left-s-line"></i>
                </Link>
                <h2>Order History</h2>
              </div>
            </div>
          </header>

          {ongoing_orders && ongoing_orders.length > 0 &&
            <section className="section-t-space">
              <div className="custom-container">
                <div className="row gy-3">
                  {ongoing_orders && ongoing_orders.map((order) => (
                    <div className="col-12">
                      <div className="vertical-product-box order-box align-items-center">
                        <div className="vertical-box-img p-1">
                          <ProgressiveImage
                            delay={20}
                            src={order?.shop?.avatar}
                            placeholder={no_image}
                          >
                            {(src) => (
                              <img
                                src={src}
                                style={{
                                  width: "100%",
                                  borderRadius: "15px",
                                  objectFit: "cover",
                                  backgroundRepeat: "round",
                                  height: '5rem'
                                }}
                                className=""
                                alt="zeato"
                              />
                            )}
                          </ProgressiveImage>
                          {order?.status != 'COMPLETED' && order?.status != 'CANCELLED' ?
                            <div className="d-flex mt-2 w-100">
                              <Link to={'/order-tracking/' + order?.id} className="track-order-btn text-center" >
                                Track
                              </Link>
                            </div>
                            : null}
                          {/* {isButtonVisible(order?.created_at) &&
                            <div className="d-flex mt-2 w-100">
                              <div className="track-order-btn text-center" onClick={() => handleDelete(order?.id)}>
                                Cancel
                              </div>
                            </div>
                          } */}
                        </div>

                        <div className="vertical-box-details">
                          <div className="vertical-box-head">
                            <div className="restaurant">
                              <h5 className="dark-text" style={{ width: '75%' }}>{order?.shop?.name}</h5>
                              <h5 className="theme-color">₹{order?.invoice?.total_pay}</h5>
                            </div>
                            <div className="my-2">
                              {JSON.parse(order?.invoice?.order_items)?.length > 0 ? (
                                JSON.parse(order?.invoice?.order_items).map((item) => (
                                  <div key={item.id}>
                                    {item.order_item_addons && item.order_item_addons.length > 0 ? (
                                      item.order_item_addons.map((addon) => (
                                        <h6 key={addon.id} className="food-items w-100" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: "600" }}>
                                          <span>{addon.addon_category_name} <span style={{ fontSize: '12px', fontWeight: "600" }}>({addon.addon_name})</span></span> x {addon.addon_quantity}
                                        </h6>
                                      ))
                                    ) : (
                                      <h6 className="food-items w-100" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: "600" }}>
                                        <span> {item.name}</span>  x {item.quantity}
                                      </h6>
                                    )}
                                  </div>
                                ))
                              ) : null}
                            </div>
                          </div>
                          <div className="reorder">
                            <h6 className="rating-star">
                              <ul className="timing">
                                <li>
                                  <span className="star">
                                    <i className="ri-star-s-fill"></i>
                                  </span>{" "}
                                  {parseFloat(order?.shop?.rating).toFixed(1)}
                                </li>
                              </ul>
                              <span className="text-end order fw-bolder mt-0"
                                style={
                                  order?.status == 'COMPLETED' ? ({ color: '#68bf9e', fontSize: '14px' }
                                  ) : order?.status == 'CANCELLED' ? ({ color: '#b9602d', fontSize: '14px' }
                                  ) : { color: '#7c47b9', fontSize: '14px' }}>
                                {order?.status}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          }

          

          {( ongoing_orders && ongoing_orders.length == 0) &&
            <div>
              <div className='' style={{ paddingTop: '8rem' }}>
                <Lottie
                  options={noOrders}
                  height={250}
                  width={250}
                />
                <div className="text-center" style={{ fontWeight: '500' }}>
                  No Ongoing Orders Found
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default OngoingOrders;
