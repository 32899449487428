import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { applyCoupon, getAllCartCoupons } from '../../../../Redux/Store/cartReducer';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/others/track-order-loading.json';
import ConfettiExplosion from 'react-confetti-explosion';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import noCoupons from '../../../../assets/images/no-coupon.png';
import { useNavigate } from "react-router-dom";

const ListCoupons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const cartProducts = useSelector((state) => state.cart);
    const [coupons, setCoupons] = useState(false)
    const [isExploding, setIsExploding] = useState(false);
    const [errorMsg, SetErrorMsg] = useState(null);
    const couponColors = ['color-2', 'color-3', 'color-4'];

    useEffect(() => {
        setLoading(true);
        dispatch(getAllCartCoupons({ shop_id: cartProducts.shop_id })).then((response) => {
            if (response && response.payload && response.payload?.message == "success") {
                setCoupons(response.payload?.result)
                setLoading(false)
            } else {
                setLoading(false)

            }
        })
    }, []);

    const onApplyCoupon = (e, coupon) => {
        e.preventDefault();

        localStorage.setItem("coupon", coupon?.promo_code);
        localStorage.setItem("couponId", coupon?.id);
        navigate("/cart")
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className='bg-slate-50' style={{ minHeight: '100vh' }}>
            <div className='sticky w-100 d-flex align-items-center p-3 bg-white top-0' style={{ zIndex: '999999999' }}>
                <div className='d-flex align-items-center' style={{ color: '#070648' }} onClick={() => { window.history.back() }}>
                    <MdOutlineArrowBackIosNew size={25} />
                </div>
                <div className='flex-grow-1 text-center ps-3 fs-3'>Coupons</div>
            </div>
            {loading ?

                <div className='' style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div> :
                <div className='px-3 w-100 overflow-hidden'>
                    <div className='mt-4 pb-5'>
                        {coupons && coupons.length > 0 ? coupons.map((coupon, index) => (
                            <div class="col-12 mt-3">
                                <div class="coupon-box" >
                                    <div class={`coupon-discount ${couponColors[index % couponColors.length]}`}> {coupon?.promocode_type == 'percent' ? ((coupon?.discount) % 100) + '%' : coupon?.discount + '₹'} OFF</div>
                                    <div class="coupon-details">
                                        <div class="coupon-content">
                                            <div>
                                                <h5 class="fw-semibold dark-text">{coupon?.promo_code}</h5>
                                            </div>
                                            <div>
                                                <div class="coupon-code px-3 py-1" onClick={(e) => { onApplyCoupon(e, coupon) }} style={{ border: '1px solid #00000020', borderRadius: '30px' }}>
                                                    <h6 class="text-black fw-semibold">Apply</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Valid on order with items worth ₹ {coupon?.min_order_amount} or more.
                                        </p>
                                        <div class="coupon-apply">
                                            <h6 class="unlock">Add item worth ₹ {coupon?.min_order_amount} more to unlock</h6>
                                        </div>
                                    </div>
                                    <img class="img-fluid coupon-left" src="assets/images/svg/coupon-left.svg" alt="right-border" />
                                    <img class="img-fluid coupon-right" src="assets/images/svg/coupon-right.svg" alt="right-border" />
                                </div>
                            </div>
                        )) :
                            <div className='flex flex-col items-center justify-center w-100 px-5 pt-24'>
                                <img src={noCoupons} alt='delvro' style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    objectFit: "cover",
                                    backgroundRepeat: "round",
                                }} />
                                <div className="text-center mt-3 text-lg">
                                    Sorry, No Coupon
                                </div>
                            </div>}
                    </div>
                </div>
            }
            {isExploding && <ConfettiExplosion />}
            {errorMsg ?
                <div className="position-fixed text-center w-100 px-3" style={{ zIndex: '100', bottom: '30vw' }}>
                    <span className='error-message-coupon'>
                        {errorMsg}
                    </span>
                </div> : null}
        </div>
    )
}

export default ListCoupons
