import React from 'react'
import no_image from '../../assets/images/rp1.png'

function Itemdetail() {
  return (
    <div> <header>
      <div class="header-panel-lg">
        <div class="custom-container">
          <div class="panel">
            <a href="home"><i class="ri-arrow-left-s-line"></i></a>
            <a href="search"><i class="ri-search-2-line"></i></a>
          </div>
        </div>
      </div>
    </header>

      <section>
        <div class="custom-container">
          <div class="restaurant-details-box">
            <div class="restaurant-head">
              <div class="name">
                <img class="img-fluid restaurant-logo" src="assets/images/icons/brand3.png" alt="starbucks-logo" />
                <div class="d-block ms-2">
                  <h3>Starbucks</h3>
                  <h6>Coffee</h6>
                </div>
              </div>
              <div class="option">
                <a href="#">
                  <i class="ri-share-line share"></i>
                </a>

                <a href="#">
                  <i class="toggle-heart ri-heart-3-fill heart"></i>
                </a>
              </div>
            </div>

            <div class="restaurant-details">
              <div class="location">
                <h6 class="rating-star">
                  <span class="star"><i class="ri-star-s-fill"></i></span> 3.7 (1k+ Reviews)
                </h6>
                <h6 class="pt-2 light-text"><span class="dark-text">4.0km</span> Free Delivery</h6>
              </div>
              <ul class="distance">
                <li><i class="ri-map-pin-fill theme-color"></i> 1 km</li>
                <li><i class="ri-time-fill theme-color"></i> 15 min</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="custom-container">
          <div class="swiper coupon">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="offer-box">
                  <div class="offer-icon">
                    <img class="img-fluid offer" src="assets/images/icons/Offer-Discount.png" alt="offer" />
                  </div>
                  <div class="offer-content">
                    <h5>50% OFF upto & $25</h5>
                    <h6 class="light-text">Use Code MEFRGD124</h6>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="offer-box">
                  <div class="offer-icon">
                    <img class="img-fluid offer" src="assets/images/icons/Offer-Discount.png" alt="offer" />
                  </div>
                  <div class="offer-content">
                    <h5>50% OFF upto & $25</h5>
                    <h6 class="light-text">Use Code MEFRGD124</h6>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="offer-box">
                  <div class="offer-icon">
                    <img class="img-fluid offer" src="assets/images/icons/Offer-Discount.png" alt="offer" />
                  </div>
                  <div class="offer-content">
                    <h5>50% OFF upto & $25</h5>
                    <h6 class="light-text">Use Code MEFRGD124</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="food-filter">
        <div class="custom-container">
          <ul class="food-symbol">
            <li>
              <a href="#" class="food-types">
                <img class="img-fluid img" src="assets/images/icons/veg.svg" alt="veg" />
                <h6>Veg</h6>
                <i class="ri-close-line close"></i>
              </a>
            </li>
            <li>
              <a href="#" class="food-types active">
                <img class="img-fluid img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                <h6>Non Veg</h6>
                <i class="ri-close-line close"></i>
              </a>
            </li>
            <li>
              <a href="#" class="food-types">
                <i class="ri-award-fill award"></i>
                <h6>Best Seller</h6>
                <i class="ri-close-line close"></i>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section class="food-list-section section-b-space">
        <div class="custom-container">
          <div class="list-box">
            <h3 class="fw-semibold dark-text">Recommended</h3>
            <div class="accordion food-accordion" id="accordionPanelsStayOpenaccordion1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="collapseOne">Featured
                    Food (04)</button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample ">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Sweet Bakery
                    (08)</button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Savoury Bakery
                    (08)</button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">Sandwiches & Wrap
                    (08)</button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Salad & Muesli
                    (08)</button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Desserts (08)</button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="list-box">
            <h3 class="mt-3 fw-semibold dark-text">Drinks</h3>

            <div class="accordion food-accordion" id="accordionPanelsStayOpenaccordion2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-heading1">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">Espresso (04)</button>
                </h2>
                <div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="heading1"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-heading2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Freshly Brewed Coffee
                    (08)</button>
                </h2>
                <div id="collapse2" class="accordion-collapse collapse show" aria-labelledby="heading2"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-heading3">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">Coffee Frappu (08)</button>
                </h2>
                <div id="collapse3" class="accordion-collapse collapse show" aria-labelledby="heading3"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-heading4">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">Cold Brew (08)</button>
                </h2>
                <div id="collapse4" class="accordion-collapse collapse show" aria-labelledby="heading4"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-heading5">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">Other Beverages
                    (08)</button>
                </h2>
                <div id="collapse5" class="accordion-collapse collapse show" aria-labelledby="heading5"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="product-box2">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Mexican Shred Beef</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src={no_image} alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Tacos</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp2.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Burrito Bowl</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp3.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                    <div class="product-box2 mt-4">
                      <div class="product-content">
                        <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                        <h5 class="product-name">Chicken Recipe</h5>
                        <div class="d-flex align-items-center gap-1">
                          <ul class="rating-stars">
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                            <li><i class="ri-star-fill stars"></i></li>
                          </ul>
                          <h5 class="dark-text">1k+ Rating</h5>
                        </div>
                        <div class="product-price">
                          <h6 class="fw-semibold"><span>$6</span> / $12</h6>
                        </div>
                        <p class="mb-0 mt-2 pt-2">A super easy Mexican style shred beef cooked...Read More</p>
                      </div>
                      <div class="product-img">
                        <a href="#product-popup" data-bs-toggle="offcanvas">
                          <img class="img-fluid img" src="assets/images/product/rp4.png" alt="rp1" />
                        </a>
                        <div class="add-btn">
                          <a class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal"><i
                            class="ri-add-line"></i> ADD</a>
                        </div>
                        <div class="plus-minus">
                          <i class="ri-subtract-line sub"></i>
                          <input type="number" value="1" min="1" max="10" />
                          <i class="ri-add-line add"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="modal filter-modal" id="add-product" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="custom-container">
              <div class="filter-header section-t-space">
                <h1 class="title">Custom Food</h1>
                <a href="#" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
              </div>
              <div class="filter-body section-t-space">
                <div class="horizontal-product-box">
                  <div class="product-img">
                    <img class="img-fluid img" src={no_image} alt="rp1" />
                  </div>
                  <div class="product-content">
                    <h5>Mexican Shred Beef</h5>
                    <h6>Hot beef stick</h6>
                    <div class="plus-minus">
                      <i class="ri-subtract-line sub"></i>
                      <input type="number" value="1" min="1" max="10" />
                      <i class="ri-add-line add"></i>
                    </div>
                    <img class="red-symbol" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                    <h3 class="product-price">$40</h3>
                  </div>
                </div>

                <div class="filter-box section-t-space">
                  <h3 class="fw-semibold dark-text">Choose Size</h3>

                  <ul class="filter-list mt-3">
                    <li>
                      <h5 class="product-size">Size S</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$18</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck1" />
                      </div>
                    </li>
                    <li>
                      <h5 class="product-size">Size M</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$20</label>
                        <input class="form-check-input" type="checkbox" id="reverseCheck2" checked />
                      </div>
                    </li>
                    <li>
                      <h5 class="product-size">Size L</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$22</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck3" />
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="body-title section-t-space">
                  <h3 class="fw-semibold dark-text">Choose Size</h3>

                  <ul class="filter-list mt-3">
                    <li>
                      <h5 class="product-size">Chili sauce</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$18</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck4" />
                      </div>
                    </li>
                    <li>
                      <h5 class="product-size">Mustard</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$20</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck5" checked />
                      </div>
                    </li>
                    <li>
                      <h5 class="product-size">Special dipping sauce</h5>
                      <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$22</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck6" />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="body-title d-flex justify-content-between section-t-space section-b-space">
                  <h3 class="fw-semibold dark-text d-flex align-items-center">Grand Total</h3>
                  <h2 class="theme-color">$40</h2>
                </div>
              </div>
              <div class="filter-footer">
                <a href="#" class="btn theme-btn apply-btn w-100" data-bs-dismiss="modal"
                  data-bs-toggle="offcanvas">Apply</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-popup">
        <div class="price-items">
          <h3>$18</h3>
          <h6>2 item Added</h6>
        </div>
        <a href="cart" class="btn theme-btn cart-btn mt-0">View Cart</a>
      </div>
      <div class="offcanvas fade offcanvas-bottom product-detail-popup" tabindex="-1" id="product-popup">
        <div class="product-box-modal ratio_40">
          <div class="product-img">
            <img class="bg-img" src={no_image} alt="rp1" />
          </div>
          <div class="product-content">
            <div class="product-info">
              <img class="img" src="assets/images/svg/nonveg.svg" alt="non-veg" />
              <h5 class="product-name">Mexican Shred Beef</h5>
              <div class="d-flex align-items-center gap-2 mb-2">
                <ul class="rating-stars">
                  <li><i class="ri-star-fill stars"></i></li>
                  <li><i class="ri-star-fill stars"></i></li>
                  <li><i class="ri-star-fill stars"></i></li>
                  <li><i class="ri-star-fill stars"></i></li>
                  <li><i class="ri-star-fill stars"></i></li>
                </ul>
                <h5 class="dark-text">1k+ Rating</h5>
              </div>
              <div class="product-price">
                <h6 class="fw-semibold"><span>$6</span> / $12</h6>
              </div>
            </div>
            <div class="plus-minus">
              <i class="ri-subtract-line sub"></i>
              <input type="number" value="1" min="1" max="10" />
              <i class="ri-add-line add"></i>
            </div>
          </div>
          <p>A super easy Mexican style shredded beef cooked in a slow cooker with smoky chipotle chilies with a hint of
            cocoa and cinnamon.</p>
          <ul class="ingredient-list">
            <li>2-3 pounds beef roast (such as chuck, eye of round, etc.)</li>
            <li>1 tablespoon ground chipotle chilis</li>
            <li>2 tablespoons ground ancho chilis</li>
            <li>1 teaspoon ground cumin</li>
            <li>1 tablespoon oregano</li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Itemdetail