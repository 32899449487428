import React from "react";

function Test() {

    return (
        <section class="search-section">
            <div class="custom-container">
                <form class="auth-form search-head" target="_blank">
                    <div class="form-group">
                        <div class="form-input" style={{ width: '1000% !important' }}>
                            <input type="search" class="form-control search" id="inputusername" placeholder="Restaurant, item & more" />
                            <i class="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Test