
import React, { useEffect, useState } from 'react'
import ProgressiveImage from 'react-progressive-image';
import { useSelector } from 'react-redux';
import { IMAGE_BASE_URL } from '../../../../config';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/others/track-order-loading.json';

function Brandlist() {
  const foodTopBrands = useSelector((state) => state.home?.home_data?.data?.foodTopBrands);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 100);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
  return (
    <div>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>
          <header class="section-t-space">
            <div class="custom-container">
              <div class="header-panel">
                <a href="home">
                  <i class="ri-arrow-left-s-line"></i>
                </a>
                <h2>Brands</h2>
              </div>
            </div>
          </header>

          <section class="categories section-lg-b-space">
            <div class="custom-container">
              <div class="row ratio_square">
                {foodTopBrands && foodTopBrands?.length > 0 ?
                  foodTopBrands?.map((category) =>
                    <div class="col-4 mt-2">
                      {/* <a href="listing"> */}
                      <div class="categories">
                        <ProgressiveImage
                          delay={20}
                          src={IMAGE_BASE_URL + category.image}
                          placeholder={
                            "assets/images/icons/brand1.png"
                          }
                        >
                          {(src) => (
                            <img
                              src={src}
                              style={{ borderRadius: '50%' }}
                              class="img-fluid categories-img"
                              alt="deleevro"
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                      <h6>{category?.shop_name}</h6>
                      {/* </a> */}
                    </div>
                  )
                  :
                  <div>
                    No Items Found
                  </div>
                }
              </div>
            </div>
          </section>
        </div>
      }
    </div>
  )
}

export default Brandlist