import React from "react";
import { Link } from "react-router-dom";

function NotificationSetting() {
  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to={'/profile'}>
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Complete Address</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- notification section start --> */}
      <section>
        <div className="custom-container">
          <ul className="notification-setting">
            <li>
              <div className="notification pt-0">
                <h5 className="fw-semibold dark-text">Offer Update</h5>
                <div className="switch-btn">
                  <input type="checkbox" checked />
                </div>
              </div>
            </li>

            <li>
              <div className="notification">
                <h5 className="fw-semibold dark-text">Order Update</h5>
                <div className="switch-btn">
                  <input type="checkbox" />
                </div>
              </div>
            </li>

            <li>
              <div className="notification pb-0">
                <h5 className="fw-semibold dark-text">New Update</h5>
                <div className="switch-btn">
                  <input type="checkbox" checked />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* <!-- notification section end --> */}
    </div>
  );
}

export default NotificationSetting;
