import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addAddonsCart, addToCart, checkCouponValid, clearCart, clearCoupon, removeAddonsCart, removeFromCart } from '../../../Redux/Store/cartReducer';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL } from '../../../config';
import no_image from '../../../assets/images/rp1.png'

function CartItems({ items, calculateCharges }) {
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart);
    const [errorMsg, setErrorMsg] = useState(null);

    const checkCoupon = (newData) => {
        let total = cartProducts.totalAmount - newData?.selling_price;
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon !== null && parseFloat(cartProducts?.appliedCoupon?.upto) >= total) {
            const formData = new FormData();
            let cartProduct = JSON.stringify(cartProducts.items)
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("token", localStorage.getItem('device_token'));
            formData.append("coupon", cartProducts?.appliedCoupon?.coupon_code);
            formData.append("subtotal", total);
            formData.append("store_id", cartProducts.shop_id);
            formData.append("cartProducts", cartProduct);
            dispatch(checkCouponValid(formData)).then((response) => {
                if (response && response.payload && response.payload.success) {
                    console.log('coupon valid')
                } else {
                    setErrorMsg(response.payload.message ?? "Somthing Went Wrong")
                    setTimeout(() => {
                        setErrorMsg(null)
                    }, 2000);
                    dispatch(clearCoupon());
                }
            })
        }
    }

    useEffect(() => {
        calculateCharges();
    }, [items])

    return (
        <div>
            <section>
                <div class="custom-container">
                    <h3 class="fw-semibold dark-text">Cart Items</h3>
                    {items.map((item) => (
                        item?.addonProducts?.length > 0 ?
                            item?.addonProducts?.map((addon) => (
                                <div class="horizontal-product-box mt-3">
                                    <div class="product-img">
                                        <ProgressiveImage
                                            delay={20}
                                            src={item.image}
                                            placeholder={no_image}
                                        >
                                            {(src) => (
                                                <img
                                                    src={src}
                                                    className="img-fluid img"
                                                    alt="zeato"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div class="product-content" >
                                        <h5>{item.name}</h5>
                                        <div class="plus-minus">
                                            <i class="ri-subtract-line sub"
                                                onClick={() => {
                                                    let newData = Object.assign({}, item, { quantity: 1, removeAddonId: addon.id });
                                                    dispatch(removeAddonsCart(newData));
                                                }}></i>
                                            <input type="number" value={addon.quantity} min="1" max="10" />
                                            <i class="ri-add-line add" onClick={() => {
                                                let newData = Object.assign({}, item, { quantity: 1, addAddonId: addon.id });
                                                dispatch(addAddonsCart(newData));
                                            }} ></i>
                                        </div>
                                        {item.food_type == 'non-veg' ?
                                            <img class="red-symbol" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                                            :
                                            <img class="red-symbol" src="assets/images/svg/veg.svg" alt="veg" />
                                        }
                                        <h3 class="product-price">₹{item.price * item.quantity}</h3>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div className='fw-700' style={{ fontWeight: '600', fontSize: '12px' }}>
                                                {"(" + addon?.name + ")"}
                                            </div>
                                            <h3 class="product-price-2">(₹{addon.price * addon.quantity})</h3>
                                        </div>
                                        {/* <div onClick={() => { dispatch(clearCart()) }}>
                                            remove
                                        </div> */}
                                    </div>
                                </div>
                            )) :
                            <div class="horizontal-product-box mt-3">
                                <div class="product-img">
                                    <ProgressiveImage
                                        delay={20}
                                        src={item.image}
                                        placeholder={no_image}
                                    >
                                        {(src) => (
                                            <img
                                                src={src}
                                                className="img-fluid img"
                                                alt="zeato"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                <div class="product-content">
                                    <h5>{item.name}</h5>
                                    {/* <h6>{item.description}</h6> */}
                                    <div class="plus-minus">
                                        <i class="ri-subtract-line sub"
                                            onClick={() => {
                                                let newData = Object.assign({}, item, { quantity: 1, });
                                                checkCoupon(newData)
                                                dispatch(removeFromCart(newData));
                                            }}></i>
                                        <input type="number" value={item.quantity} min="1" max="10" />
                                        <i class="ri-add-line add" onClick={() => {
                                            let newData = Object.assign({}, item, { quantity: 1, shop_id: cartProducts?.shop_id });
                                            dispatch(addToCart(newData));
                                        }} ></i>
                                    </div>
                                    {item.food_type == 'non-veg' ?
                                        <img class="red-symbol" src="assets/images/svg/nonveg.svg" alt="non-veg" />
                                        :
                                        <img class="red-symbol" src="assets/images/svg/veg.svg" alt="veg" />
                                    }
                                    <h3 class="product-price">₹{item.selling_price * item.quantity}</h3>
                                    {/* <div onClick={() => { dispatch(clearCart()) }}>
                                        remove
                                    </div> */}
                                </div>
                            </div>
                    ))}
                </div>
            </section>
            {errorMsg ?
                <div className="position-fixed text-center w-100 px-3" style={{ zIndex: '100', bottom: '30vw' }}>
                    <span className='error-message-coupon'>
                        {errorMsg}
                    </span>
                </div> : null}
        </div>
    )
}

export default CartItems