import React, { useEffect, useState } from 'react'
import no_image from '../../../../assets/images/rp1.png'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/others/track-order-loading.json';
import emptyItem from '../../../../assets/others/noItems.json';
import FloatCart from '../../../FloatCart';
import ProgressiveImage from 'react-progressive-image';
import { getSingleCategory } from '../../../../Redux/Store/storeReducer';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { addToCart, removeFromCart } from '../../../../Redux/Store/cartReducer';
import { IMAGE_BASE_URL } from '../../../../config';

function CategoryItems() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    let { id } = useParams();

    const products = useSelector((state) => state?.store?.category_items?.category);

    useEffect(() => {
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("token", localStorage.getItem("device_token"));
        formData.append("category_id", id);
        dispatch(getSingleCategory(formData)).then((response) => {
            if (response.payload.success) {
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((error) => {
            console.error("Fetching store failed", error);
            setLoading(false);
        });
    }, []);


    const cartProducts = useSelector((state) => state.cart);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const emptyItems = {
        loop: true,
        autoplay: true,
        animationData: emptyItem,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div>
            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :
                <div>
                    <header class="section-t-space">
                        <div class="custom-container">
                            <div class="d-flex align-items-center pb-2">
                                <div onClick={() => { window.history.back() }}>
                                    <i class="ri-arrow-left-s-line fs-3"></i>
                                </div>
                                <h2 className='flex-grow-1 text-center' style={{ fontWeight: '550' }}>{products?.name}</h2>
                            </div>
                        </div>
                    </header>
                    <div className='pt-4' style={{ paddingBottom: '25vw' }}>
                        {products?.products?.length > 0 ?
                            <section class="products section-t-space section-b-space">
                                <div class="custom-container">
                                    <div class="row gy-4">
                                        {products?.products?.map((product) => (
                                            <div class="col-6 pt-4">
                                                <div class="product-box product-box-bg">
                                                    <a href="details.html" class="product-box-img">
                                                        <ProgressiveImage
                                                            delay={20}
                                                            src={IMAGE_BASE_URL + product.image}
                                                            placeholder={no_image}
                                                        >
                                                            {(src) => (
                                                                <img
                                                                    src={src}
                                                                    className="img-fluid img"
                                                                    alt="deleevro"
                                                                    style={{ width: '100%', height: '30vw', borderRadius: '15px', objectFit: 'cover' }} />
                                                            )}
                                                        </ProgressiveImage>
                                                    </a>
                                                    <div class="product-box-detail">
                                                        <h5>{product?.name}</h5>
                                                        <ul className="timing">
                                                            {product?.shop?.shop_name}
                                                        </ul>
                                                        <div className="bottom-panel">
                                                            <div className="price">₹{product.selling_price}</div>
                                                            {cartProducts?.items?.find((cp) => cp.id === product?.id) !== undefined ? (
                                                                <div>
                                                                    {cartProducts?.items?.filter((cp) => cp.id === product?.id).map((cartProduct) => (
                                                                        <div className="d-flex justify-content-between align-items-center" key={cartProduct.id} >
                                                                            <div className="home-item-remove" onClick={() => {
                                                                                let newData = Object.assign({}, cartProduct, {
                                                                                    quantity: 1,
                                                                                });
                                                                                dispatch(removeFromCart(newData));
                                                                            }}>
                                                                                <FaMinus />
                                                                            </div>
                                                                            <span className='px-1'>
                                                                                {cartProduct.quantity}
                                                                            </span>
                                                                            <div className="home-item-add" onClick={() => {
                                                                                let newData = Object.assign({}, cartProduct, {
                                                                                    quantity: 1,
                                                                                });
                                                                                dispatch(addToCart(newData));
                                                                            }} >
                                                                                <FaPlus />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) :
                                                                <div className="cart mb-0" onClick={() => {
                                                                    let newData = Object.assign({}, product, {
                                                                        quantity: 1,
                                                                    });
                                                                    dispatch(addToCart(newData))
                                                                }}>
                                                                    <i className="ri-shopping-cart-2-fill"></i>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </section>
                            :
                            <div className="d-flex flex-column align-items-center justify-center" style={{ paddingTop: '2rem' }}>
                                <Lottie
                                    options={emptyItems}
                                    height={300}
                                    width={300}
                                />
                                <div style={{ fontWeight: '550' }}>
                                    No Items
                                </div>
                            </div>}
                    </div>
                    <FloatCart type={true} />
                </div>
            }
        </div>
    )
}

export default CategoryItems