import React, { useEffect, useState } from 'react'
import CartItems from './CartItems';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import empty_cart from '../../assets/others/empty_cart.json'
import loadingLottie from '../../assets/others/track-order-loading.json'
import BillDetails from './BillDetails';
import DeliveryAddress from './DeliveryAddress';
import ApplyCoupon from './ApplyCoupon';
import Tip from './Tip';
import { Drawer } from "@material-ui/core";
import { RiCloseCircleLine } from 'react-icons/ri';
import moment from 'moment';
import { calculateShopChargeTax } from '../../Redux/Store/cartReducer';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import 'swiper/css';
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import Footer from '../Home/Footer';
import { useNavigate } from "react-router-dom";

function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.cart);
  const [tip, setTip] = useState('');
  const [deliveryType, setDeliveryType] = useState(true);
  const [deliverySheet, setDeliverySheet] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(localStorage.getItem('schedule_time') ?? null);
  const [scheduledDate, setScheduledDate] = useState(localStorage.getItem('schedule_date') ?? null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cartData, setCartData] = useState(null);
  const [delivery_charge, setDeliveryCharge] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [alert, setAlert] = useState(null);
console.log("cart data",cartData);
  //updated starts
  let schedule_date = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_date") : null;
  let schedule_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_time") : null;
  let schedule_start_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_start_time") : null;
  const [selectedDate, setSelectedDate] = useState(schedule_date )
  const [selectedTime, setSelectedTime] = useState(schedule_time)
  const [stateChange, setStateChange] = useState(false)
  const [startTime, setStartTime] = useState(schedule_start_time)
  //updated ends
console.log("cartProducts",cartProducts)
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("tip") > 0) {
      setTip(localStorage.getItem("tip"))
    } else {
      setTip(0)
    }
    if (localStorage.getItem("scheduledDate") && localStorage.getItem("scheduledTime")) {
      setDeliveryType(false)
      setIsScheduled(true)
      setScheduledDate(localStorage.getItem("scheduledDate"))
      setScheduledTime(localStorage.getItem("scheduledTime"))
    }

    calculateCharges();

    if (!localStorage.getItem("addressId")) {
      navigate("/location");
    }
  }, []);

  const addTip = (e) => {
    if (e == tip) {
      setTip(0)
      localStorage.setItem("tip", 0)
    } else {
      setTip(e)
      localStorage.setItem("tip", e)
    }
  };

  const calculateTip = () => {
    if (tip) {
      return tip;
    } else {
      return 0;
    }
  };

  const calculateCharges = () => {
    let items = cartProducts?.items;

    if (items?.length > 0) {
      dispatch(calculateShopChargeTax({
        calculateCharge: true,
        delivery_lat: localStorage.getItem("userLat"),
        delivery_long: localStorage.getItem("userLng"),
        deliveryTip: 0,
        items: items,
        promocode: {
          "code": localStorage.getItem('coupon')
        },
        shopId: cartProducts.shop_id,
        user_address_id: localStorage.getItem("addressId")
      })).then((response) => {
        if (response?.payload?.message == 'success') {
          setCartData(response?.payload?.result);
          setDeliveryCharge(response?.payload?.result?.orders?.delivery_charge)
          if (response?.payload?.result?.promocode?.status == 'success') {
            setDiscount(parseFloat(response?.payload?.result?.promocode?.discount).toFixed(2))
          } else {
            setDiscount(0);
          }
          if (!response?.payload?.result?.deliveryOptions?.isDeliverable) {
            setAlert(response?.payload?.result?.deliveryOptions?.message)
          } else {
            setAlert(null)
          }
          if (response?.payload?.result?.changes && !alert) {
            setAlert(response?.payload?.result?.changes[0])
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }



  



  


  // const totalPrice = () => {
    // let discount = 0;
    // if (cartProducts?.appliedCoupon) {
    //   if (cartProducts?.appliedCoupon?.discount_type === "amount") {
    //     discount += parseFloat(cartProducts?.appliedCoupon?.used_amount);
    //   } else {
    //     discount += parseFloat(cartProducts?.totalAmount * parseFloat(cartProducts?.appliedCoupon?.used_amount) / 100);
    //     let max_discount = parseFloat(cartProducts?.appliedCoupon?.upto);
    //     if (max_discount > 0 && max_discount <= discount) {
    //       discount = parseFloat(cartProducts?.appliedCoupon?.upto);
    //     }
    //   }
    // }

    // let tax = 0;
    // let surge_charge = 0;
    // if (cartProducts?.shop_tax?.success) {
    //   tax = cartProducts?.shop_tax?.tax > 0 ? ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) : 0;
    //   surge_charge = cartProducts?.shop_tax?.surge_charge;
    // }

    // let packing_charge = 0;
    // if (cartProducts?.shop_distance?.shop_id?.packing_charge && cartProducts?.shop_distance?.shop_id?.packing_charge > 0) {
    //   packing_charge = cartProducts?.shop_distance?.shop_id?.packing_charge;
    // }

  //   if (discount > 0) {
  //     return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
  //   } else {
  //     return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
  //   }
  // }

  const totalPrice = () => {
        
        let tax = 0;
        let surge_charge = cartData?.orders?.surge_charge; 
  

        let service_charge = cartData?.orders?.extra_charges?.service_charge?.serviceChargeAmount || 0;
        

        let packing_charge = cartData?.orders?.extra_charges?.packing_charge?.packingChargeAmount || 0;

        if (discount > 0) {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(service_charge)+ parseFloat(packing_charge)).toFixed(2)
        } else {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge)+ parseFloat(service_charge) + parseFloat(packing_charge))
        }
    }




  const checkToken = () => {
    setLoading(true);
    navigate("/managePayment", { state: { cartData: cartData } });
  }

  const itemCount = () => {
    let count = 0
    if (cartProducts?.items?.length > 0) {
      cartProducts?.items?.map((orderItem) => {
        if (orderItem?.addonProducts?.length > 0) {
          count += orderItem.addonProducts.length;
        } else {
          count += 1;
        }
      })
      return parseInt(count);
    } else {
      return count;
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_cart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const loadingLott = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const today = new Date();
  const next10Days = Array.from({ length: 5 }, (_, index) => {
    const date = new Date();
    date.setDate(today.getDate() + index);
    return {
      week: date.toLocaleDateString('en-US', { weekday: 'short', }),
      month: date.toLocaleDateString('en-US', { month: 'short' }),
      day: date.toLocaleDateString('en-US', { day: 'numeric' }),
      date: date.toLocaleDateString('en-US', { day: 'numeric', month: 'short'}),
      isToday: index === 0, // Check if it's today
    };
  });

  const generateTimeRanges = (selectedDate) => {
    const now = new Date(selectedDate);
    const currentTime = new Date();
    if (now < currentTime) {
      now.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    }

    const formatTime = (time) => {
      return time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }).replace(/^0/, '').replace(/\s/g, ''); // Remove spaces
    };

    const formatTimes = (time) => {
      return time.toLocaleTimeString('en-US');
    };

    const timeRanges = [];
    for (let i = 9; i <= 19; i++) {
      const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), i, 0, 0);
      const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
      const startingTime = formatTimes(startTime);
      const timeRange = `${formatTime(startTime)} - ${formatTime(endTime)}`;
      const isTimeRangeEnded = now.getTime() + 60 * 60 * 1000 >= endTime.getTime();
      timeRanges.push({ timeRange, isTimeRangeEnded, startingTime });
    }

    return timeRanges;
  };

  const change = () => {
    if (stateChange) {
      setSelectedTime(null)
      setStateChange(false)
    }
  }

  const handleCategory = () => {
  }


 const scheduledays = cartProducts?.shop_tax?.result?.scheduleData && cartProducts?.shop_tax?.result?.scheduleData
  // console.log( scheduledays,"scheduleDataaa")

  const handleDateClick = (day) => {
    setSelectedDate(day.day);
  };

  const getTimeForSelectedDate = () => {
    const selectedDay = scheduledays?.find((day) => day?.day === selectedDate);
    if (selectedDay) {
      return {
        startTimes: JSON.parse(selectedDay?.start_time),
        endTimes: JSON.parse(selectedDay?.end_time),
      };
    }
    return { startTimes: [], endTimes: [] };
  };

  

  const { startTimes, endTimes } = getTimeForSelectedDate();
console.log("startTimes",startTimes);


  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":").map(Number);
    const period = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${period}`;
  };
  

  

  return (
    <div>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={loadingLott}
            height={100}
            width={100}
          />
        </div>
        : <>
          <header class="section-t-space">
            <div class="custom-container">
              <div className="d-flex align-items-center">
                <div onClick={() => { window.history.back() }}>
                  <i className="ri-arrow-left-s-line fs-5"></i>
                </div>
                <h2 className="flex-grow-1 text-center" style={{ fontWeight: '600' }}>Cart</h2>
              </div>
            </div>
          </header>
          {cartProducts?.items?.length > 0 ?
            <>
              <CartItems items={cartProducts?.items} calculateCharges={calculateCharges} />
              <ApplyCoupon cartData={cartData} calculateCharges={calculateCharges} setDiscount={setDiscount} discount={discount} />
              <Tip addTip={(e) => addTip(e)} tips={cartData?.orders?.extra_charges?.delivery_tip?.tipAmounts}
                tip={tip} />
              <DeliveryAddress />
              <section class="delivery address">
                <h3 class="fw-semibold dark-text px-3">Delivery Type </h3>
                <div className='mt-3'>
                  <div className="  d-flex flex-row align-items-center justify-content-between px-4 mt-4"  >
                    <div className={deliveryType ? "instant-type" : "schedule-type "} onClick={() => {
                      setDeliveryType(true)
                      setIsScheduled(false)
                      localStorage.removeItem("scheduledDate")
                      localStorage.removeItem("scheduledTime")
                    }}>
                      Instant
                    </div>
                    <div className={deliveryType ? "schedule-type" : "instant-type"} onClick={() => {
                      setDeliveryType(false)
                      setDeliverySheet(true)
                      setIsScheduled(true)
                    }}>
                      Schedule
                    </div>
                  </div>
                </div>

                {!deliveryType && schedule_date && schedule_time ?
                  <div className=' text-center fw-bolder mt-2 px-4 p-2'>
                    <div className='schedule-type w-100'>
                      <div>Scheduled Date & Time</div>
                      <div> Schedule date <span>{schedule_date.split(' ')[0] + ' ' + schedule_date.split(' ')[1]}</span></div>
                      <div> Schedule Time <span> {convertTo12HourFormat(schedule_time)}</span></div>
                      {/* {convertTo12HourFormat(startTime)} - {convertTo12HourFormat(endTimes[index])} */}
                    </div>
                  </div> : null}
              </section>
              <BillDetails tip={calculateTip()} cartData={cartData} discount={discount} />

              <div class="pay-popup">
                {alert ? (
                  <div className="w-100 text-center alert alert-warning alert-dismissible fade show" role="alert">
                    {alert}
                  </div>
                ) : (
                  <>
                    <div class="price-items">
                      {/* <h3>₹ {totalPrice()}</h3> */}
                      <h3>₹ {totalPrice()}</h3>
                      
                      <h6>{itemCount()} item Added</h6>
                    </div>
                    <div class="btn theme-btn pay-btn mt-0" onClick={() => checkToken()} style={{color:'#000' }}>Pay Now</div>
                  </>
                )}
              </div>
            </>
            :
            <div className="flex items-center justify-center" style={{ paddingTop: '5rem' }}>
              <Lottie
                options={defaultOptions}
                height={300}
                width={300}
              />
              <div className='text-center pt-5 fs-2' style={{ fontWeight: '600' }}>
                Your Cart is Empty
              </div>
              <div className='text-center pt-1 fs-6 text-muted' style={{ fontWeight: '600' }}>
                Add Items To Cart, Keep Shopping
              </div>
            </div>
          }
          <Drawer
            anchor="bottom"
            open={deliverySheet}
            onClose={() => setDeliverySheet(false)}
            swipeAreaWidth={15} >
            <div style={{ borderRadius: '15px 15px 0px 0px' }}>
              <div className='p-3' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='sticky top-20vh'>
                  <div className='d-flex justify-content-end align-items-end'>
                    <div onClick={() => { setDeliverySheet(false) }}>
                      <RiCloseCircleLine size={25} />
                    </div>
                  </div>
                  <div className='flex flex-start text-lg px-2 pt-3 pb-2 mt-2' style={{ fontWeight: "600" }} >  Choose Schedule Date & Time</div>
                  <div className='ps-3'>
                    
                  </div>
                </div>

                <div className="ps-3">
        <Swiper slidesPerView={3} spaceBetween={0}>
          {scheduledays?.map((day, index) => (
            <SwiperSlide key={index} style={{ width: "100px" }}>
              <div
                className="schedulecards flex flex-col items-center px-2"
                onClick={() => handleDateClick(day)}
                style={
                  selectedDate === day.day
                    ? { backgroundColor: "#fbdb2c", color: "white" }
                    : { backgroundColor: "#f8f9fa", color: "#000" }
                }
              >
                {/* <div>{day.day }</div> */}

                <div>{new Date(day.day).toLocaleString('default', { month: 'long', day: 'numeric' })}</div>

                
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Time Slots */}
      <div className="flex flex-col items-center justify-between mt-3" style={{ paddingBottom: "6rem" }}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2} justifyContent="space-between" className="px-3">
            {startTimes.map((startTime, index) => (
              <Typography
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  background: "#F8F9FA",
                  border: "1px solid #fbdb2c",
                }}
                key={index}
              >
                <div
                  className="d-flex flex-row items-center px-2 py-2"
                  style={{ justifyContent: "space-between" }}
                  onClick={() => setSelectedTime(startTime)}
                >
                  <div>
                    {selectedTime === startTime ? (
                      <IoIosRadioButtonOn size={24} color="#fbdb2c" />
                    ) : (
                      <IoIosRadioButtonOff size={24} />
                    )}
                  </div>
                  
                  <div className="grow text-start px-3"> {convertTo12HourFormat(startTime)}</div>
                  <div className="text-end px-3">{convertTo12HourFormat(endTimes[index])}</div>
                </div>
              </Typography>
            ))}
          </Stack>
        </Box>
      </div>
              </div>
              <div className="position-fixed bottom-0 py-2 px-3 pb-6 mt-2  bg-color-white w-100 bg-white" style={{ width: "100%" }}>
                <button className="send-otp-button " onClick={() => {
                  localStorage.setItem('is_schedule', true);
                  localStorage.setItem("schedule_date", selectedDate);
                  localStorage.setItem("schedule_time", selectedTime);
                  localStorage.setItem("schedule_start_time", startTime);
                  // navigate('/checkout')
                  setDeliverySheet(false)
                }}>Set Schedule</button>
              </div>
            </div>
          </Drawer>
        </>
      }
      {cartProducts.items?.length > 0 ? null :
        <Footer category={'CART'}
         changeCategory={handleCategory} />
      }
    </div>
  )
}

export default Cart