import React from 'react'

function Order() {
  return (
    <div> <header class="section-t-space">
    <div class="custom-container">
      <div class="header-panel">
        <a href="order-tracking">
          <i class="ri-arrow-left-s-line"></i>
        </a>
        <h2>Order Tracking</h2>
      </div>
    </div>
  </header>
  <section class="bill-details section-lg-b-space">
    <div class="custom-container">
      <div class="total-detail mt-3">
        <div class="sub-total">
          <h5>Sub Total</h5>
          <h5 class="fw-semibold">$58</h5>
        </div>
        <h5 class="mt-3">Delivery Charge (2 kms)</h5>
        <h5 class="free">Free</h5>
        <h6 class="delivery-info light-text mt-2">Save $5 on Delivery fee by ordering above $30</h6>
        <div class="sub-total pb-3">
          <h5>Discount (20%)</h5>
          <h5 class="fw-semibold">$9.6</h5>
        </div>
        <div class="grand-total">
          <h5 class="fw-semibold">Grand Total</h5>
          <h5 class="fw-semibold amount">$48.4</h5>
        </div>
        <img class="dots-design" src="assets/images/svg/dots-design.svg" alt="dots" />
      </div>
      <div class="delivery-time mt-4">
        <p class="delivery-line mb-0 m-0 p-0">A Moments of Delivered on Time</p>
        <img class="img-fluid delivery-bike" src="assets/images/gif/delivery.png" alt="delivery" />
      </div>
    </div>
  </section>
  </div>
  )
}

export default Order