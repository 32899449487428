import React from "react";

function Pharmacy() {
  return (
    <div className="pharmacy-color">
      {/* <!-- side bar start --> */}
      <div
        className="offcanvas sidebar-offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasLeft"
      >
        <div className="offcanvas-header">
          <img
            className="img-fluid profile-pic"
            src="assets/images/icons/profile.png"
            alt="profile"
          />
          <h3>Hello, Lina John</h3>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content">
            <ul className="link-section">
              <li>
                <div className="pages">
                  <h3>RTL</h3>
                  <div className="switch-btn">
                    <input id="dir-switch" type="checkbox" />
                  </div>
                </div>
              </li>
              <li>
                <div className="pages">
                  <h3>Dark</h3>
                  <div className="switch-btn">
                    <input id="dark-switch" type="checkbox" />
                  </div>
                </div>
              </li>

              <li>
                <a href="page-listing" className="pages">
                  <h3>Pages List</h3>
                  <i className="ri-arrow-drop-right-line"></i>
                </a>
              </li>

              <li>
                <a href="setting" className="pages">
                  <h3>Setting</h3>
                  <i className="ri-arrow-drop-right-line"></i>
                </a>
              </li>

              <li>
                <a href="#" className="pages">
                  <h3>Privacy Policy</h3>
                  <i className="ri-arrow-drop-right-line"></i>
                </a>
              </li>

              <li>
                <a href="login" className="pages">
                  <h3>Logout</h3>
                  <i className="ri-arrow-drop-right-line"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- side bar end --> */}

      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <div className="head-content">
              <button
                className="sidebar-btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasLeft"
              >
                <i className="ri-menu-line"></i>
              </button>
              <div className="header-location">
                <a href="#location" data-bs-toggle="modal">
                  <h2>Ontario, Canada</h2>
                  <i className="ri-arrow-down-s-line d-arrow"></i>
                </a>
              </div>
            </div>
            <a href="notification">
              <i className="ri-notification-2-fill"></i>
            </a>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- search section starts --> */}
      <section className="search-section">
        <div className="custom-container">
          <form className="auth-form search-head" target="_blank">
            <div className="form-group">
              <div className="form-input">
                <input
                  type="search"
                  className="form-control search"
                  id="inputusername"
                  placeholder="Restaurant, item & more"
                />
                <i className="ri-search-line search-icon"></i>
              </div>

              <a
                href="#search-filter"
                className="btn filter-button mt-0"
                data-bs-toggle="modal"
              >
                <i className="ri-equalizer-line"></i>
              </a>
            </div>
          </form>
        </div>
      </section>
      {/* <!-- search section end --> */}

      {/* <!-- categories section start --> */}
      <section className="search-section">
        <div className="custom-container">
          <div className="title">
            <h3 className="mt-0">Top Categories</h3>
            <a href="categories">View All</a>
          </div>
          <div className="swiper pharmacy-categories">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="text-center">
                  <div className="pharmacy-categories-box active">
                    <img src="assets/images/pharmacy/svg/1.svg" alt="1" />
                  </div>
                  <h6 className="mt-1  light-text">Medicine</h6>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="text-center">
                  <div className="pharmacy-categories-box">
                    <img src="assets/images/pharmacy/svg/2.svg" alt="2" />
                  </div>
                  <h6 className="mt-1  light-text">Covid Care</h6>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="text-center">
                  <div className="pharmacy-categories-box">
                    <img src="assets/images/pharmacy/svg/3.svg" alt="3" />
                  </div>
                  <h6 className="mt-1  light-text">Body Care</h6>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="text-center">
                  <div className="pharmacy-categories-box">
                    <img src="assets/images/pharmacy/svg/4.svg" alt="4" />
                  </div>
                  <h6 className="mt-1  light-text">Product</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- categories section start --> */}

      {/* <!-- banner section starts --> */}
      <section className="search-section">
        <div className="custom-container">
          <img
            className="img-fluid pharmacy-banner-img"
            src="assets/images/pharmacy/banner15.png"
            alt="banner15"
          />
        </div>
      </section>
      {/* <!-- banner section end --> */}

      {/* <!-- offer product section starts --> */}
      <section className="search-section">
        <div className="custom-container">
          <div className="title">
            <div className="d-flex gap-1">
              <img
                className="img-fluid"
                src="assets/images/pharmacy/svg/lock.svg"
                alt="lock"
              />
              <h3 className="mt-0">Offer Zone</h3>
            </div>
            <a href="details">View All</a>
          </div>

          <div className="swiper grocery-product">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="grocery-product-box pharmacy-product-box">
                  <h6 className="offer-tag">20% off</h6>
                  <div className="like-icon animate active inactive">
                    <i className="ri-heart-3-line outline-icon"></i>
                    <i className="ri-heart-3-fill fill-icon"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                  <div className="grocery-product-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/pharmacy/p1.png"
                      alt="p1"
                    />
                  </div>
                  <div className="grocery-product-details">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="dark-text">Pulse Oximeter</h4>
                      <div className="d-flex align-items-center">
                        <i className="ri-time-line light-text"></i>
                        <h6 className="light-text lh-1">20min</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h4 className="theme-color price">$10</h4>
                      <div className="cart-btn">
                        <a href="cart">
                          <img
                            className="img-fluid cart-icon"
                            src="assets/images/pharmacy/svg/bag.svg"
                            alt="bag"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="grocery-product-box pharmacy-product-box">
                  <h6 className="offer-tag">20% off</h6>
                  <div className="like-icon animate">
                    <i className="ri-heart-3-line outline-icon"></i>
                    <i className="ri-heart-3-fill fill-icon"></i>
                    <div className="effect-group">
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                      <span className="effect"></span>
                    </div>
                  </div>
                  <div className="grocery-product-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/pharmacy/p2.png"
                      alt="p2"
                    />
                  </div>
                  <div className="grocery-product-details">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="dark-text">Beurer Ih 18</h4>
                      <div className="d-flex align-items-center ">
                        <i className="ri-time-line light-text"></i>
                        <h6 className="light-text lh-1">20min</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h4 className="theme-color price">$10</h4>
                      <div className="cart-btn">
                        <a href="cart">
                          <img
                            className="img-fluid cart-icon"
                            src="assets/images/pharmacy/svg/bag.svg"
                            alt="bag"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- offer product section end --> */}

      {/* <!-- popular product section starts --> */}
      <section>
        <div className="custom-container">
          <div className="title mb-0">
            <div className="d-flex gap-1">
              <img
                className="img-fluid"
                src="assets/images/pharmacy/svg/lock.svg"
                alt="lock"
              />
              <h3 className="mt-0">Trending Product</h3>
            </div>
            <a href="details">View All</a>
          </div>

          <ul className="grocery-horizontal-product-box pharmacy-horizontal-product-box gap-0 mt-3">
            <li className="w-100">
              <div className="horizontal-product-img">
                <img
                  className="img-fluid img"
                  src="assets/images/pharmacy/p3.png"
                  alt="p3"
                />
              </div>
              <div className="horizontal-product-details">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-semibold dark-text">Genuine health deep</h5>

                  <div className="d-flex align-items-center ">
                    <img
                      className="img-fluid"
                      src="assets/images/svg/rating-star.svg"
                      alt="star"
                    />
                    <h6 className="rate-color">4.5</h6>
                  </div>
                </div>
                <h6 className="light-text mt-1">Captab 15</h6>
                <h4 className="light-text price mt-1 mb-1">
                  <span className="theme-color">$15 </span> <del>$20</del>
                </h4>

                <a href="cart" className="btn theme-btn add-btn mt-0">
                  + Add
                </a>
              </div>
            </li>

            <li className="w-100">
              <div className="horizontal-product-img">
                <img
                  className="img-fluid img"
                  src="assets/images/pharmacy/p4.png"
                  alt="p4"
                />
              </div>
              <div className="horizontal-product-details">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-semibold dark-text">Fatigue Wellness</h5>

                  <div className="d-flex align-items-center ">
                    <img
                      className="img-fluid"
                      src="assets/images/svg/rating-star.svg"
                      alt="star"
                    />
                    <h6 className="rate-color">4.5</h6>
                  </div>
                </div>
                <h6 className="light-text mt-1">Ashvagandha</h6>
                <h4 className="light-text price mt-1 mb-1">
                  <span className="theme-color">$15 </span> <del>$20</del>
                </h4>

                <a href="cart" className="btn theme-btn add-btn mt-0">
                  + Add
                </a>
              </div>
            </li>
            <li className="w-100">
              <div className="horizontal-product-img">
                <img
                  className="img-fluid img"
                  src="assets/images/pharmacy/p5.png"
                  alt="p4"
                />
              </div>
              <div className="horizontal-product-details">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-semibold dark-text">Ener-C Orange</h5>

                  <div className="d-flex align-items-center ">
                    <img
                      className="img-fluid"
                      src="assets/images/svg/rating-star.svg"
                      alt="star"
                    />
                    <h6 className="rate-color">4.5</h6>
                  </div>
                </div>
                <h6 className="light-text mt-1">Sugar Free</h6>
                <h4 className="light-text price mt-1 mb-1">
                  <span className="theme-color">$15 </span> <del>$20</del>
                </h4>

                <a href="cart" className="btn theme-btn add-btn mt-0">
                  + Add
                </a>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* <!-- popular product section end --> */}

      {/* <!-- product section starts --> */}
      <section className="search-section">
        <div className="custom-container">
          <div className="title ">
            <div className="d-flex gap-1">
              <img
                className="img-fluid"
                src="assets/images/pharmacy/svg/lock.svg"
                alt="lock"
              />
              <h3>Recommended for you</h3>
            </div>
            <a href="details">View All</a>
          </div>

          <div className="swiper grocery-product">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="grocery-product-box pharmacy-product-box">
                  <h6 className="offer-tag">20% off</h6>
                  <div className="ratings d-flex align-items-center ">
                    <img
                      className="img-fluid"
                      src="assets/images/svg/rating-star.svg"
                      alt="star"
                    />
                    <h6 className="rate-color">4.5</h6>
                  </div>
                  <div className="grocery-product-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/pharmacy/p1.png"
                      alt="p1"
                    />
                  </div>
                  <div className="grocery-product-details">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="dark-text">Pulse Oximeter</h4>
                      <div className="d-flex align-items-center">
                        <i className="ri-time-line light-text"></i>
                        <h6 className="light-text lh-1">20min</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h4 className="theme-color price">$10</h4>
                      <div className="cart-btn">
                        <a href="cart">
                          <img
                            className="img-fluid cart-icon"
                            src="assets/images/pharmacy/svg/bag.svg"
                            alt="bag"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="grocery-product-box pharmacy-product-box">
                  <h6 className="offer-tag">20% off</h6>
                  <div className="ratings d-flex align-items-center ">
                    <img
                      className="img-fluid"
                      src="assets/images/svg/rating-star.svg"
                      alt="star"
                    />
                    <h6 className="rate-color">4.5</h6>
                  </div>
                  <div className="grocery-product-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/pharmacy/p2.png"
                      alt="p2"
                    />
                  </div>
                  <div className="grocery-product-details">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="dark-text">Beurer Ih 18</h4>
                      <div className="d-flex align-items-center ">
                        <i className="ri-time-line light-text"></i>
                        <h6 className="light-text lh-1">20min</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h4 className="theme-color price">$10</h4>
                      <div className="cart-btn">
                        <a href="cart">
                          <img
                            className="img-fluid cart-icon"
                            src="assets/images/pharmacy/svg/bag.svg"
                            alt="bag"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- product section end --> */}

      {/* <!-- special discount section starts --> */}
      <section className="section-b-space">
        <div className="custom-container">
          <div className="title">
            <div className="d-flex gap-1">
              <img
                className="img-fluid"
                src="assets/images/pharmacy/svg/lock.svg"
                alt="lock"
              />
              <h3 className="mt-0">Special Discount</h3>
            </div>
            <a href="details">view All</a>
          </div>

          <div className="swiper discount-banner">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img
                  className="img-fluid discount-banner"
                  src="assets/images/pharmacy/banner16.png"
                  alt="banner16"
                />
              </div>
              <div className="swiper-slide">
                <img
                  className="img-fluid discount-banner"
                  src="assets/images/pharmacy/banner17.png"
                  alt="banner16"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- special discount section end --> */}

      {/* <!-- Best Seller section starts --> */}
      <section className="section-b-space">
        <div className="custom-container">
          <div className="title">
            <div className="d-flex gap-1">
              <img
                className="img-fluid"
                src="assets/images/pharmacy/svg/lock.svg"
                alt="lock"
              />
              <h3 className="mt-0">Best Seller Product</h3>
            </div>
            <a href="details">view All</a>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <div className="grocery-horizontal-product-box2 pharmacy-horizontal-product-box2 ">
                <div className="horizontal-product-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/pharmacy/p6.png"
                    alt="p6"
                  />
                </div>
                <div className="horizontal-product-details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold dark-text">GT's Kombucha-PURE</h5>

                    <div className="d-flex align-items-center ">
                      <img
                        className="img-fluid"
                        src="assets/images/svg/rating-star.svg"
                        alt="star"
                      />
                      <h6 className="rate-color">4.5</h6>
                    </div>
                  </div>
                  <h6 className="light-text mt-1">Nebuliser</h6>
                  <h4 className="light-text price mt-1 mb-1">
                    <span className="theme-color">$5 </span> <del>$10</del>
                  </h4>

                  <a href="cart" className="btn theme-btn add-btn mt-0">
                    + Add
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="grocery-horizontal-product-box2 pharmacy-horizontal-product-box2">
                <div className="horizontal-product-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/pharmacy/p7.png"
                    alt="p7"
                  />
                </div>
                <div className="horizontal-product-details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold dark-text">
                      Phoeapolis Organics Oil
                    </h5>

                    <div className="d-flex align-items-center ">
                      <img
                        className="img-fluid"
                        src="assets/images/svg/rating-star.svg"
                        alt="star"
                      />
                      <h6 className="rate-color">4.5</h6>
                    </div>
                  </div>
                  <h6 className="light-text mt-1">Organic Olive Oil</h6>
                  <h4 className="light-text price mt-1 mb-1">
                    <span className="theme-color">$8 </span>
                  </h4>

                  <a href="cart" className="btn theme-btn add-btn mt-0">
                    + Add
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="grocery-horizontal-product-box2 pharmacy-horizontal-product-box2">
                <div className="horizontal-product-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/pharmacy/p8.png"
                    alt="p8"
                  />
                </div>
                <div className="horizontal-product-details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold dark-text">Immunity booster</h5>

                    <div className="d-flex align-items-center ">
                      <img
                        className="img-fluid"
                        src="assets/images/svg/rating-star.svg"
                        alt="star"
                      />
                      <h6 className="rate-color">4.5</h6>
                    </div>
                  </div>
                  <h6 className="light-text mt-1">La grande’s</h6>
                  <h4 className="light-text price mt-1 mb-1">
                    <span className="theme-color">$10 </span>
                  </h4>

                  <a href="cart" className="btn theme-btn add-btn mt-0">
                    + Add
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="grocery-horizontal-product-box2 pharmacy-horizontal-product-box2">
                <div className="horizontal-product-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/pharmacy/p9.png"
                    alt="p9"
                  />
                </div>
                <div className="horizontal-product-details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold dark-text"> Multi-Vitamin Tablet</h5>

                    <div className="d-flex align-items-center ">
                      <img
                        className="img-fluid"
                        src="assets/images/svg/rating-star.svg"
                        alt="star"
                      />
                      <h6 className="rate-color">4.5</h6>
                    </div>
                  </div>
                  <h6 className="light-text mt-1">OMEGA-3</h6>
                  <h4 className="light-text price mt-1 mb-1">
                    <span className="theme-color">$15 </span>
                  </h4>

                  <a href="cart" className="btn theme-btn add-btn mt-0">
                    + Add
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="grocery-horizontal-product-box2 pharmacy-horizontal-product-box2">
                <div className="horizontal-product-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/pharmacy/p10.png"
                    alt="p10"
                  />
                </div>
                <div className="horizontal-product-details">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold dark-text">Body Lotion</h5>

                    <div className="d-flex align-items-center ">
                      <img
                        className="img-fluid"
                        src="assets/images/svg/rating-star.svg"
                        alt="star"
                      />
                      <h6 className="rate-color">4.5</h6>
                    </div>
                  </div>
                  <h6 className="light-text mt-1">Green pack</h6>
                  <h4 className="light-text price mt-1 mb-1">
                    <span className="theme-color">$6 </span>
                  </h4>

                  <a href="cart" className="btn theme-btn add-btn mt-0">
                    + Add
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Best Seller section end --> */}

      {/* <!-- panel-space start --> */}
      <section className="panel-space"></section>
      {/* <!-- panel-space end --> */}

      {/* <!-- bottom navbar start --> */}
      <div className="navbar-menu">
        <ul>
          <li>
            <a href="landing" className="icon logo">
              <img
                className=" img-fluid"
                src="assets/images/svg/pharmacy-logo.svg"
                alt="logo"
              />
              <span>Zomo</span>
            </a>
          </li>
          <li>
            <a href="food-home" className="icon">
              <img
                className="unactive"
                src="assets/images/svg/food.svg"
                alt="food"
              />
              <img
                className="active"
                src="assets/images/svg/food-fill.svg"
                alt="food"
              />
              <span>Food</span>
            </a>
          </li>
          <li>
            <a href="grocery-home" className="icon">
              <img
                className="unactive"
                src="assets/images/svg/grocery.svg"
                alt="grocery"
              />
              <img
                className="active"
                src="assets/images/svg/grocery-fill.svg"
                alt="grocery"
              />
              <span>Grocery</span>
            </a>
          </li>

          <li className="active">
            <a href="pharmacy-home" className="icon">
              <img
                className="unactive"
                src="assets/images/svg/pharmacy.svg"
                alt="pharmacy"
              />
              <img
                className="active"
                src="assets/images/svg/pharmacy-fill.svg"
                alt="pharmacy"
              />
              <span>Pharmacy</span>
            </a>
          </li>
          <li>
            <a href="profile" className="icon">
              <img
                className="unactive"
                src="assets/images/svg/user.svg"
                alt="user"
              />
              <img
                className="active"
                src="assets/images/svg/user-fill.svg"
                alt="user"
              />
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </div>
      {/* <!-- bottom navbar end --> */}
    </div>
  );
}

export default Pharmacy;
