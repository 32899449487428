import React from 'react'

function Notification() {
  return (
    <div><header class="section-t-space">
    <div class="custom-container">
      <div class="header-panel">
        <a href="home">
          <i class="ri-arrow-left-s-line"></i>
        </a>
        <h2>Notification</h2>
      </div>
    </div>
  </header>
  <section class="section-b-space pt-0">
    <div class="custom-container">
      <ul class="notification">
        <li class="notification-box unread">
          <div class="notification-icon">
            <span>
              <img class="notification-img img1" src="assets/images/svg/close.svg" alt="close" />
            </span>
          </div>
          <div class="notification-content">
            <h5>Order Cancelled !!</h5>
            <h6>Just Now</h6>
            <p>Your order is cancelled due to some kitchen issues.i hope you understand this and thank you in advance
              for support us.</p>
          </div>
        </li>
        <li class="notification-box unread">
          <div class="notification-icon">
            <span>
              <img class="notification-img img1" src="assets/images/svg/add.svg" alt="add" />
            </span>
          </div>
          <div class="notification-content">
            <h5>New Service Available!</h5>
            <h6>2 min ago</h6>
            <p>You can make multiple food orders at one tile you can also cancel orders.</p>
          </div>
        </li>
        <li class="notification-box">
          <div class="notification-icon">
            <span>
              <img class="notification-img" src="assets/images/svg/money-tick.svg" alt="money-tick" />
            </span>
          </div>
          <div class="notification-content">
            <h5>Credit Card Connected</h5>
            <h6>06:15 pm</h6>
            <p>Your credit card successfully connected with foodfest.</p>
          </div>
        </li>
        <li class="notification-box">
          <div class="notification-icon">
            <span>
              <img class="notification-img" src="assets/images/svg/profile-circle.svg" alt="profile-circle" />
            </span>
          </div>
          <div class="notification-content">
            <h5>Account Successfully Updated</h5>
            <h6>05:00 pm</h6>
            <p>Your account is successfully updated, you can now experience our new service.</p>
          </div>
        </li>
        <li class="notification-box">
          <div class="notification-icon">
            <span>
              <img class="notification-img" src="assets/images/svg/discount-circle.svg" alt="discount-circle" />
            </span>
          </div>
          <div class="notification-content">
            <h5>New Offer</h5>
            <h6>04:00 pm</h6>
            <p>Flat 20% off on all type of pizza from any restaurant, #valid till 30 Nov, 2022.</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
  </div>
  )
}

export default Notification